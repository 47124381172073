import toast from 'react-hot-toast'
export const successToast = (message: string) => {
  toast(message, {
    style: {
      backgroundColor: '#009fb7',
      color: '#fafafa',
    },
  })
}

export const errorToast = (message: string) => {
  toast(message, {
    style: {
      backgroundColor: '#d32f2f',
      color: '#fafafa',
    },
  })
}
