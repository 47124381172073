import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { EmailWrapper } from "./style";
import emailImg from "../../assets/images/email.png";
import { getCookie } from 'esstart-components';
import { useDispatch } from 'react-redux';
import { resendEmail } from '../../redux/auth';

export const EmailExists = () => {
  const userName = getCookie('username');
  const dispatch = useDispatch<any>();
  const handleResetEmail = (e: any) => {
    e.preventDefault();
    if(userName){
      dispatch(resendEmail({userName}))
    }
  }
  return (
    <Dialog open={true}>
      <EmailWrapper>
        <DialogContent>
          <div className='image'>
            <img src={emailImg} alt="Email" />
          </div>
          <span className='heading'><h3>Email Already Exists</h3></span>
          <p className='first-para'>Your email <b> {userName} </b> is not confirmed. Please confirm your account to continue</p>
          <span className='last-para'>
              <p>Did not recieve the email ? <a href="/#" onClick={(e) => handleResetEmail(e)} className='anchor'> CLICK HERE</a> to resent </p>
              <p>Have any Login Issues ? Contact Support Team</p>
        </span>
        </DialogContent>
      </EmailWrapper>
    </Dialog>
  )
};