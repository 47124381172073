import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { Button, TextField, CircularProgress } from '@mui/material'
import { confirmEmailForgotPwd } from '../../redux/auth'
import email from '../../assets/images/email.png'
import { Modal } from 'esstart-components'
import { ForgotPasswordWrapper } from './style'
import CloseIcon from '../../assets/images/svg/close_icon.svg'

interface IProps {
  openForgetPwdModal: string
  setOpenForgetPwdModal: Function
}

export const ForgotPassword = ({
  openForgetPwdModal,
  setOpenForgetPwdModal,
}: IProps) => {
  const dispatch = useDispatch<any>()

  const [loading, toggleLoading] = useState<boolean>(false)

  interface IErrorSingIn {
    email?: string
  }

  const confirmEmailValidate: any = (values: any) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i

    const errors = {} as IErrorSingIn

    if (!values.email) {
      errors.email = 'Required'
    } else if (!emailPattern.test(values.email)) {
      errors.email = 'Valid Email Required'
    }

    return errors
  }

  const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        email: '',
      },
      validate: confirmEmailValidate,
      onSubmit: async (values: any) => {
        const { email } = values
        await invokeVerifyEmailApi(email.toLowerCase())
        setOpenForgetPwdModal('emailSentModal')
      },
    })

  const invokeVerifyEmailApi = async (email: any) => {
    toggleLoading(true)
    await dispatch(confirmEmailForgotPwd({ username: email.toLowerCase() }))
    toggleLoading(false)
  }

  return (
    <ForgotPasswordWrapper>
      <Modal
        size="md"
        open={openForgetPwdModal === 'enterEmailModal' ? true : false}
      >
        <div className="d-flex">
          <h2>Forgot Password</h2>
          <img
            src={CloseIcon}
            alt="close icon"
            className="close-icon"
            onClick={() => setOpenForgetPwdModal('')}
          />
        </div>
        <p className="mt-3 mb-4">
          Enter your registered email address with Esstart. We will send you a
          link to reset your password.
        </p>
        <form onSubmit={handleSubmit} className="forgot-pwd-form">
          <div className="input-field pt-0 mt-4">
            <span>Enter Email Address</span>
            <TextField
              fullWidth
              // label="Email ID"
              id="email"
              className="textField"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.email && !!touched.email}
            />
          </div>

          {errors.email && touched.email ? (
            <div className="errorMsg">{errors.email as string}</div>
          ) : null}
          <div className="button-container">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                className="button-container__start remove_btn_hover"
                type="submit"
              >
                Continue
              </Button>
            )}
          </div>
        </form>
      </Modal>
      <Modal
        size="md"
        open={openForgetPwdModal === 'emailSentModal' ? true : false}
      >
        <div className="verify-email-container">
          <div className="text-center">
            <img src={email} alt="Verfiy" />
            <img
              src={CloseIcon}
              alt="close icon"
              className="close-icon"
              onClick={() => setOpenForgetPwdModal('')}
            />
          </div>
          <span className="heading">Verify Your Email Address</span>
          <p className="first-para mt-3">
            You've entered <b> {values.email.toLowerCase() || ''} </b> as the
            email address. We have sent you an email, please verify and reset
            your password
          </p>
          <p className="my-4">
            Did not receive the email ?{' '}
            <span
              className="resend-email"
              onClick={() => invokeVerifyEmailApi(values.email.toLowerCase())}
            >
              CLICK HERE
            </span>{' '}
            to resend
          </p>
        </div>
      </Modal>
    </ForgotPasswordWrapper>
  )
}
