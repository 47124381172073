import React, { useState } from 'react'
import { useSearchParams, useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import {
  Button,
  TextField,
  IconButton,
  Container,
  Box,
  CircularProgress,
} from '@mui/material'
import { SignInWrapper } from '../login/style'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Logo from '../../assets/logo/web_logo.svg'
import VisibleOn from '../../assets/images/show-eye.svg'
import VisibleOff from '../../assets/images/hide-eye.svg'
import { resetPassword } from '../../redux/auth'
import { getCookie } from 'esstart-components'
import { emailPattern } from '../../constant/constant'
import { errorToast, successToast } from '../toasts'

export const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch<any>()
  const confirmationCode = searchParams.get('confirmationCode')
  const [showPassword, setShowPassword] = useState(false)
  const username = getCookie('username')

  const navigate = useNavigate()

  const product: any = useSelector(
    (state: any) => state.products?.selectedProduct
  )

  const onHomeClick = () => {
    const url: any = process.env.REACT_APP_WEBSITE_URL_LIVE
    window.location.href = url
  }

  const [loading, toggleLoading] = useState<boolean>(false)

  interface IErrorSingIn {
    email?: string
    password?: string
    confirmPassword?: string
  }

  const confirmEmailValidate: any = (values: any) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/

    const errors = {} as IErrorSingIn
    if (!values.email?.trim()) {
      errors.email = 'Email Required'
    } else if (!emailPattern.test(values.email?.trim())) {
      errors.email = 'Valid Email Required'
    }
    if (!passwordPattern.test(values.password)) {
      errors.password = 'Please match the password criteria.'
    }
    if (values.password !== values.confirmPassword)
      errors.confirmPassword = 'Passwords must be same'

    return errors
  }

  const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        email: username || '',
        password: '',
        confirmPassword: '',
      },
      validate: confirmEmailValidate,
      onSubmit: async (values: any) => {
        const { email, password, confirmPassword } = values
        toggleLoading(true)

        const res = await dispatch(
          resetPassword({
            username: username || email?.toLowerCase()?.trim(),
            newPassword: password,
            confirmationCode,
          })
        )
        if (res?.payload?.status === 'success') {
          successToast('Password changed successfully !')
          navigate('/login')
        } else {
          errorToast('Something went wrong !')
        }
        toggleLoading(false)
      },
    })

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <SignInWrapper>
      <Box className="login_background">
        <Container>
          <div className="form__wrapper login_page">
            <div className="content_part">
              <div className="login_menu" onClick={onHomeClick}>
                <IconButton>
                  <ArrowBackIosIcon />
                </IconButton>
                <p>Home</p>
              </div>
              <img src={Logo} alt="Logo" className="website_logo" />
              {!!product ? (
                <div className="product_box">
                  <img src={product?.cardImgURL} alt="Product" />
                  <h3>{product?.displayTitle}</h3>
                  <p>{product?.shortDesc1} </p>
                  <p>{product?.shortDesc2}</p>
                </div>
              ) : (
                <h5>
                  Your One stop shop for<p>Unforgettable</p>Party Entertainment
                </h5>
              )}
            </div>
            <div className="login_form">
              <>
                <Box className="page_banner">
                  <h2 className="title">Reset Password</h2>
                  <p className="subtitle">Create your new password!</p>
                </Box>
                <form onSubmit={handleSubmit}>
                  {!username && (
                    <div className="input-field">
                      <span>Enter Email</span>
                      <TextField
                        fullWidth
                        type="email"
                        id="email"
                        className="textField"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.email && !!touched.email}
                      />
                    </div>
                  )}

                  {errors.email && touched.email ? (
                    <div className="errorMsg">{errors.email as string}</div>
                  ) : null}
                  <div className="input-field">
                    <span>Enter Password</span>
                    <TextField
                      fullWidth
                      type="password"
                      // label="Enter Password"
                      id="password"
                      className="textField"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.password && !!touched.password}
                    />
                  </div>
                  {errors.password && touched.password ? (
                    <div className="errorMsg">{errors.password as string}</div>
                  ) : null}
                  <div className="input-field">
                    <span>Confirm Password</span>
                    <TextField
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      id="confirmPassword"
                      className="textField"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        !!errors.confirmPassword && !!touched.confirmPassword
                      }
                      InputProps={{
                        endAdornment: (
                          <div
                            className="cursor-pointer"
                            onClick={handleClickShowPassword}
                          >
                            <img
                              src={showPassword ? VisibleOff : VisibleOn}
                              alt="toggle show password"
                            />
                          </div>
                        ),
                      }}
                    />
                  </div>
                  <span
                    style={{
                      display: 'block',
                      font: 'italic normal normal 14px/12px Plus Jakarta Sans',
                      marginTop: '5px',
                      color: '#707070',
                    }}
                  >
                    (Recommended: 8 characters with Numbers, Caps and Letters)
                  </span>
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <div className="errorMsg">
                      {errors.confirmPassword as string}
                    </div>
                  ) : null}

                  <span className="forgotPwd">
                    <Link to="/login">Back to Login</Link>
                  </span>
                  {/* </Typography> */}

                  <div className="button-container">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        className="button-container__start remove_btn_hover"
                        type="submit"
                      >
                        Reset Password
                      </Button>
                    )}
                  </div>
                </form>
              </>
            </div>
          </div>
        </Container>
      </Box>
    </SignInWrapper>
  )
}
