import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { deletek, put, axiosAuth, getCookie } from 'esstart-components'

import Cookies from 'js-cookie'

export const settingName = 'settings'

const initialState = {
  removeAccountData: '',
  loading: false,
  userInfoData: null,
  rejectUserInfo: '',
}

const url = `${process.env.REACT_APP_API_AUTH_URL}/`
const apiURL = `${process.env.REACT_APP_API_URL}/`

export const removeAccount = createAsyncThunk(
  'products/removeAccount',
  async () => {
    const config = {
      headers: {
        Authorization: Cookies.get('accessToken') as string,
        Accept: 'application/json',
      },
    }
    const res = await deletek(`${url}delete-account`, config)
    return res?.data
  }
)

export const editUserInfo = createAsyncThunk(
  'products/editUserInfo',
  async (args: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: Cookies.get('accessToken') as string,
          Accept: 'application/json',
        },
      }
      const res = await put(`${apiURL}my/v1/my-info`, args, config)
      return { data: res?.data, args }
    } catch (err: any) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const verifyMobileNumOtp = createAsyncThunk(
  'guest/verify-otp',
  async (args: any) => {
    const accessToken = getCookie('accessToken')
    const url = 'users/verify/mobile'
    const { data } = await axiosAuth.post(url, args, {
      headers: {
        Authorization: accessToken!,
      },
    })
    return data
  }
)

export const removeMessage = createAsyncThunk(
  'products/removeMessage',
  async () => {
    return ''
  }
)

export const settingsSlice = createSlice({
  name: settingName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(removeAccount.pending, (state) => {
      state.loading = true
    })
    builder.addCase(removeAccount.rejected, (state) => {
      state.loading = false
      state.removeAccountData = 'remove'
    })
    builder.addCase(removeAccount.fulfilled, (state) => {
      state.removeAccountData = 'remove'
      state.loading = false
    })
    builder.addCase(editUserInfo.pending, (state, action) => {
      if (!action?.meta?.arg?.productTourStatus) {
        state.loading = true
      }
      state.rejectUserInfo = ''
    })
    builder.addCase(editUserInfo.rejected, (state, action: any) => {
      state.loading = false
      state.rejectUserInfo = action.payload.message
    })
    builder.addCase(editUserInfo.fulfilled, (state, action) => {
      state.loading = false
      state.userInfoData = action.payload.args
      state.rejectUserInfo = ''
    })
    builder.addCase(removeMessage.fulfilled, (state) => {
      state.loading = false
      state.rejectUserInfo = ''
    })
    builder.addCase(verifyMobileNumOtp.pending, (state) => {
      state.loading = true
    })
    builder.addCase(verifyMobileNumOtp.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(verifyMobileNumOtp.rejected, (state) => {
      state.loading = false
    })
  },
})

export const settingReducer = settingsSlice.reducer

// ReducerRegistry.register(settingName, settingReducer);
