import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { getProduct } from '../../redux/products'
import { NavLink } from 'react-router-dom'
import {
  Button,
  Box,
  Container,
  TextField,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material'
import { SignUpWrapper, EmailWrapper } from './style'
import email from '../../assets/images/email.png'
import VisibleOff from '../../assets/images/hide-eye.svg'
import VisibleOn from '../../assets/images/show-eye.svg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Logo from '../../assets/logo/web_logo.svg'
import { signUpUser, resendEmail } from '../../redux/auth'
import SocialLogin from '../socialLogin/socialLogin'
import { getCookie } from 'esstart-components'
import ReCAPTCHA from 'react-google-recaptcha'
import Cookies from 'js-cookie'

export const Signup = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const productId = searchParams.get('productId')
  const planId = searchParams.get('planId')
  const from = searchParams.get('from')
  const userSource = searchParams.get('userSource') || Cookies.get('userSource')
  const [showVerify, toggleVerify] = useState<boolean>(false)
  const [loading, toggleLoading] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)

  userSource && Cookies.set('userSource', userSource, { expires: 1 })

  planId &&
    Cookies.set('planId', planId, {
      expires: 1,
    })
  productId &&
    Cookies.set('productId', productId, {
      expires: 1,
    })
  from &&
    Cookies.set('from', from, {
      expires: 1,
    })

  const product: any = useSelector(
    (state: any) => state.products.selectedProduct
  )
  useEffect(() => {
    if (productId) {
      dispatch(getProduct({ productId: productId }))
    }
  }, [searchParams, dispatch])

  const onHomeClick = () => {
    const url: any =
      process.env.REACT_APP_WEBSITE_URL_LIVE || 'https://www.esstart.com/'
    window.location.href = url
  }

  const onSignupSuccess = () => {
    const next = localStorage.getItem('next')
    let url = `${
      productId ? `/dashboard?productId=${productId}` : '/dashboard'
    }`
    const queryString = localStorage.getItem('queryString')
    if (next) {
      url = `${next}`
      localStorage.removeItem('next')
    }
    if (queryString) {
      localStorage.removeItem('queryString')
    }
    window.location.href = `${url}`
    navigate(url)
  }

  let captcha: any
  const setCaptchaRef = (ref: any) => {
    if (ref) {
      return (captcha = ref)
    }
  }

  interface IErrorSingUp {
    name?: string
    email?: string
    password?: string
    reCaptcha?: string
  }

  const signUpValidate: any = (values: any) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i

    const errors = {} as IErrorSingUp
    if (!values.name?.trim()) {
      errors.name = 'Please enter your name'
    }
    if (!values.email) {
      errors.email = 'Please enter the email'
    } else if (!emailPattern.test(values.email)) {
      errors.email = 'Valid Email Required'
    }

    if (!values.password) {
      errors.password = 'Please enter the password'
    } else if (!passwordPattern.test(values.password)) {
      errors.password = 'Please match the password criteria.'
    }

    if (!values.reCaptcha) {
      errors.reCaptcha = 'Please enter the captcha'
    }

    return errors
  }
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    setFieldError,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      name: '',
      phoneNum: '',
      email: '',
      password: '',
      reCaptcha: '',
    },
    validate: signUpValidate,
    onSubmit: async (values: any) => {
      const { name, email, password, reCaptcha, phoneNum } = values
      if (email && password) {
        toggleLoading(true)
        const windowUrl = window.location.search
        const queryParams = new URLSearchParams(windowUrl)
        const productId: string = queryParams.get('productId') || ''
        const payload = {
          source: userSource || 'source-unknown',
          name,
          email: email?.toLowerCase()?.trim(),
          password,
          reCaptcha,
          productId,
          phoneNum: phoneNum?.length > 3 ? phoneNum : undefined,
        }
        const res = await dispatch(
          signUpUser(
            productId ? { ...payload, testProduct: productId } : { ...payload }
          )
        )
        if (res.type === 'auth/signup/fulfilled') {
          //@ts-ignore
          // zohoFormBtnRef?.current?.click()
          onSignupSuccess()
        }
        if (res.type === 'auth/signup/rejected') {
          setFieldError('reCaptcha', res.payload?.message)
          captcha.reset()
        }
        toggleLoading(false)
      }
    },
  })

  const isFormCompletelyFilled =
    !!values?.name?.trim() &&
    !!values?.email &&
    !!values?.password &&
    !!values?.reCaptcha &&
    Object.keys(errors)?.length === 0

  const handleResetEmail = (e: any) => {
    e.preventDefault()
    const userName = getCookie('username')
    if (userName) {
      dispatch(resendEmail({ userName }))
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleRecaptchaVerify = (token: any) => {
    setFieldValue('reCaptcha', token)
  }

  const phoneNumInput = (e: any) => {
    setFieldValue('phoneNum', e.target.value.replace(/[^0-9+]/g, ''))
  }

  return (
    <>
      {!showVerify && (
        <SignUpWrapper>
          <Box className="signup_background">
            <Container>
              <div className="form__wrapper signup_page">
                <div className="content_part">
                  <div className="signup_menu" onClick={onHomeClick}>
                    <IconButton>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <p>Home</p>
                  </div>
                  <img src={Logo} alt="Logo" className="website_logo" />
                  {!!product ? (
                    <div className="product_box">
                      <img src={product?.cardImgURL} alt="Card" />
                      <h3>{product?.displayTitle}</h3>
                      <p>{product?.shortDesc1}</p>
                      <p>{product?.shortDesc2}</p>
                    </div>
                  ) : (
                    <h5 className="hide-for-small-screen">
                      The Most
                      <p>
                        <span>Unforgettable </span>
                        <span>Events</span>
                      </p>
                      Start with Esstart
                    </h5>
                  )}
                </div>
                <div className="signup_form">
                  <Box className="page_banner d-flex flex-column align-items-center">
                    <h2 className="title">Create an Account !</h2>
                    <p className="subtitle">
                      Please create an account to start !
                    </p>
                    <div className="start">
                      Already have an account ?
                      <NavLink to="/login" className="start__login">
                        LOGIN HERE
                      </NavLink>
                    </div>
                    <div className="flex-row tab-view">
                      <div
                        className="cursor-pointer text-center"
                        onClick={() => navigate('/login')}
                      >
                        Login
                      </div>
                      <div className="text-center">Signup</div>
                    </div>
                    <div className="full-strike"></div>
                  </Box>
                  <form onSubmit={handleSubmit}>
                    <div className="social-wrapper">
                      <SocialLogin />
                    </div>

                    <div className="strike">
                      <span> or </span>
                    </div>

                    <div className="input-field">
                      <span>
                        Enter your name<span className="color-red">*</span>
                      </span>
                      <TextField
                        fullWidth
                        id="name"
                        type="text"
                        className="textField"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.name && !!touched.name}
                      />
                    </div>

                    {errors.name && touched.name ? (
                      <div className="errorMsg">{errors.name as string}</div>
                    ) : null}
                    <div className="input-field">
                      <span>
                        Enter your phone number (with country code)
                        <span className="color-red"></span>
                      </span>
                      <TextField
                        fullWidth
                        id="phoneNum"
                        type="text"
                        value={values?.phoneNum}
                        className="textField"
                        onChange={phoneNumInput}
                        onBlur={handleBlur}
                        error={!!errors.phoneNum && !!touched.phoneNum}
                      />
                    </div>

                    {errors.phoneNum && touched.phoneNum ? (
                      <div className="errorMsg">
                        {errors.phoneNum as string}
                      </div>
                    ) : null}

                    <div className="input-field">
                      <span>
                        Enter Email Address<span className="color-red">*</span>
                      </span>
                      <TextField
                        fullWidth
                        // label="Email ID"
                        id="email"
                        type="email"
                        className="textField"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.email && !!touched.email}
                      />
                    </div>

                    {errors.email && touched.email ? (
                      <div className="errorMsg">{errors.email as string}</div>
                    ) : null}

                    <div className="input-field">
                      <span>
                        Enter Password<span className="color-red">*</span>
                      </span>
                      <span
                        style={{
                          display: 'block',
                          font: 'italic normal normal 14px/12px Plus Jakarta Sans',
                          marginTop: '5px',
                          color: '#707070',
                        }}
                      >
                        (Recommended: 8 characters with Numbers, Caps and
                        Letters)
                      </span>

                      <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        // label="Enter Password"
                        id="password"
                        className="textField"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.password && !!touched.password}
                        InputProps={{
                          endAdornment: (
                            <div
                              className="cursor-pointer"
                              onClick={handleClickShowPassword}
                            >
                              <img
                                src={showPassword ? VisibleOff : VisibleOn}
                                alt="toggle show password"
                              />
                            </div>
                          ),
                        }}
                      />
                    </div>
                    {errors.password && touched.password ? (
                      <div className="errorMsg">
                        {errors.password as string}
                      </div>
                    ) : null}
                    <ReCAPTCHA
                      className="mt-3"
                      ref={(r) => setCaptchaRef(r)}
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY!}
                      onChange={handleRecaptchaVerify}
                    />
                    {errors.reCaptcha && touched.reCaptcha ? (
                      <div className="errorMsg">
                        {errors.reCaptcha as string}
                      </div>
                    ) : null}
                    <div className="button-container">
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          className={`button-container__start remove_btn_hover ${
                            isFormCompletelyFilled ? 'signup-started' : ''
                          }`} // signup-started class id for GTM
                          type="submit"
                        >
                          Start
                        </Button>
                      )}
                    </div>
                    <Typography
                      marginTop="15px"
                      fontSize="14px"
                      className="privacy_policychange"
                    >
                      By clicking button above, you agree to our{' '}
                      <a
                        className="anchor"
                        href="https://www.esstart.com/tos"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of use
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://www.esstart.com/p-policy"
                        className="anchor"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policies
                      </a>
                    </Typography>
                  </form>
                </div>
              </div>
            </Container>
          </Box>
        </SignUpWrapper>
      )}

      {showVerify && (
        <EmailWrapper>
          <Box className="verify_background">
            <Container>
              <div className="form__wrapper verify_page">
                <div className="content_part">
                  <div className="verify_menu" onClick={onHomeClick}>
                    <IconButton>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <p>Home</p>
                  </div>
                  <img src={Logo} alt="Logo" className="website_logo" />
                  {!!product ? (
                    <div className="product_box">
                      <img src={product?.cardImgURL} alt="Card" />
                      <h3>{product?.displayTitle}</h3>
                      <p>{product?.shortDesc1}</p>
                      <p>{product?.shortDesc2}</p>
                    </div>
                  ) : (
                    <h5>
                      The Most
                      <p>
                        <span>Unforgettable </span>
                        <span>Events</span>
                      </p>
                      Start with Esstart
                    </h5>
                  )}
                </div>
                <Box className="verify_form" display="flex" alignItems="center">
                  <Box padding={'20px 40px'}>
                    <div className="image">
                      <img src={email} alt="Verfiy" />
                    </div>
                    <span className="heading">Verify Your Email Address</span>
                    <p className="first-para">
                      You've entered <b> {values.email || ''} </b> as the email
                      address. We have sent you an email, please verify your
                      account
                    </p>

                    <span className="last-para">
                      <p>
                        Did not recieve the email ?{' '}
                        <a
                          href="/#"
                          onClick={(e) => handleResetEmail(e)}
                          className="anchor"
                        >
                          {' '}
                          CLICK HERE
                        </a>{' '}
                        to resent{' '}
                      </p>
                      <p className="last-para-text">
                        Have any Login Issues ? Contact Support Team
                      </p>
                    </span>
                  </Box>
                </Box>
              </div>
            </Container>
          </Box>
        </EmailWrapper>
      )}
    </>

    // <>
    //   <SignupView productData={product || productId} closeBtn={false} signupPage={true} onHomeClick={onHomeClick} />
    //   {/*  */}
    // </>
  )
}
