import React, { useState } from 'react'
import { Modal, Buttons } from 'esstart-components'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@mui/material'
import {
  createSlideshowFromTemplate,
  removeMyProduct,
} from '../../redux/products'
import { useDispatch } from 'react-redux'
import { editUserInfo } from '../../redux/settings'
import closeIcon from '../../assets/icons/menu_close.svg'
import timezones from '../../json/Timezones.json'
import webLogo from '../../assets/logo/web_logo.svg'
import successTick from '../../assets/images/green-tick.svg'
import { useSearchParams } from 'react-router-dom'

export const DashboardModals = (props: any) => {
  const dispatch = useDispatch<any>()

  const [searchParams, setSearchParams] = useSearchParams()
  const isPaymentSuccess = searchParams.get('payment') === 'success'

  const {
    setToggleNameModal,
    createNewSlideshowModal,
    toggleCreateNewSlideshowModal,
    setIsLoader,
    setOpenRemove,
    toggleNameModal,
    removeUrl,
    openRemove,
    products,
    nameModalSubmitFn,
  } = props

  const [name, setName] = useState('')
  const [date, setDate] = useState('')
  const [dateError, setDateError] = useState('')

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const addUserName = async (e: any) => {
    e.preventDefault()
    //@ts-ignore
    await dispatch(editUserInfo({ name }))
    setToggleNameModal(false)
    !!nameModalSubmitFn && nameModalSubmitFn(name)
  }

  const handleDateChange = ({ date }: any) => {
    setDate(() => date.format())
  }

  const invokeCreateSlideshowFromTemplate = async (e: any) => {
    e.preventDefault()
    if (!!date) {
      setDateError('')
      await dispatch(
        //@ts-ignore
        createSlideshowFromTemplate({
          slideShowId: createNewSlideshowModal,
          timeZone: timezones.filter((i: any) =>
            i.utc.includes(currentTimeZone)
          )[0].text,
          showDate: new Date(date).toISOString(),
        })
      )
      setDate('')
      toggleCreateNewSlideshowModal('')
    } else {
      setDateError('Please enter the date.')
    }
  }

  const handleRemoveProduct = () => {
    setIsLoader(true)
    dispatch(
      //@ts-ignore
      removeMyProduct({
        removeAPIurl: removeUrl,
      })
    )
    setOpenRemove(false)
  }
  const handleClose = () => {
    setIsLoader(false)
    setOpenRemove(false)
  }

  return (
    <>
      <Modal size="xl" open={isPaymentSuccess}>
        <img
          className="float-end cursor-pointer"
          src={closeIcon}
          alt="close"
          onClick={() => setSearchParams('')}
        />
        <div className="d-flex flex-column align-items-center">
          <div className="payment-modal__web-logo">
            <img className="cursor-pointer" src={webLogo} alt="website logo" />
          </div>
          <div className="payment-modal__success-msg flex-row gap-2">
            <p>Yes Its Done.</p>
            <img src={successTick} alt="tick icon" />
          </div>
          <p className="payment-modal__payment-receive-msg">
            Your payment has been received ! Celebrate the happy moments.
          </p>
        </div>
      </Modal>
      <Modal size="sm" open={openRemove}>
        <p className="dialog_desc text-center">
          Are you sure you want to delete product?
        </p>
        <div className="d-flex algn-items-center float-end mt-4">
          <Buttons
            text="Yes"
            bgColor="unset"
            borderRadius={false}
            color="#1976d2"
            fontSize="0.875rem"
            className="fw-semibold"
            padding="0px 12px"
            onClick={handleRemoveProduct}
          />
          <Buttons
            text="No"
            bgColor="unset"
            borderRadius={false}
            color="#1976d2"
            fontSize="0.875rem"
            className="fw-semibold"
            padding="0px 12px"
            onClick={handleClose}
          />
        </div>
      </Modal>
      <Modal size="md" open={toggleNameModal}>
        <h2 className="name-modal-heading text-center">
          Please enter your name to continue
        </h2>
        <form
          onSubmit={addUserName}
          className="d-flex flex-column align-items-center gap-4 mt-5 mb-4 name-form"
        >
          <input
            type="text"
            placeholder="Please enter your name"
            className="invite-code-inp"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <div className="d-flex gap-2">
            <Buttons
              text="Cancel"
              className="btn-secondary"
              bgColor="#fff"
              color="rgb(255, 114, 0)"
              borderRadius={true}
              boxShadow={false}
              padding="6px 20px"
              fontSize="0.875rem"
              onClick={() => {
                setName('')
                setToggleNameModal(false)
              }}
            />
            <Buttons
              text="Submit"
              bgColor="#ff7200"
              color="#fff"
              borderRadius={true}
              boxShadow={false}
              padding="6px 20px"
              fontSize="0.875rem"
            />
          </div>
        </form>
      </Modal>
      <Modal size="md" open={!!createNewSlideshowModal}>
        <form
          onSubmit={invokeCreateSlideshowFromTemplate}
          className="d-flex flex-column align-items-center gap-6 mb-4 create-slideshow-form"
        >
          <h3>
            Please fill the date to create new instant spotlight from existing
            one.
          </h3>
          <div className="d-flex flex-column">
            <label className="mb-2">
              Show Date<small>(approx)</small>
            </label>
            <DatePicker
              inputFormat="MM/DD/YYYY"
              minDate={new Date(Date.now() - 86400000)}
              maxDate={new Date('2040')}
              value={date}
              shouldDisableDate={(day: any) =>
                products?.allProductsDates?.includes(
                  day?.format()?.slice(0, 10)
                )
              }
              onChange={(date) => {
                handleDateChange({ date })
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            {dateError && <p className="error">{dateError}</p>}
          </div>
          <div className="d-flex gap-2">
            <Buttons
              text="Create"
              bgColor="#ff7200"
              color="#fff"
              borderRadius={true}
              boxShadow={false}
              padding="6px 20px"
              fontSize="0.875rem"
            />
            <button
              type="button"
              className="btn-secondary cancel-btn cursor-pointer"
              onClick={() => {
                toggleCreateNewSlideshowModal('')
                setDate('')
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}
