import React, { useContext } from 'react'
import { useRoutes } from 'react-router-dom'
import { AuthContext, removeAuthCookies } from 'esstart-components'
import { routes } from './routes'

// default routes
export default function EsstartRoutes() {
  const authData = useContext(AuthContext)
  const routing = useRoutes(routes(authData))

  // if admin comes on this url ?rbsh=rbsh, clear the tokens so that admin can set them again
  // if (
  //   window.location.search.includes('rbsh=rbsh') &&
  //   authData?.authStatus === 1
  // ) {
  //   removeAuthCookies()
  //   window.location.href = `${window.location.origin}/login?rbsh=rbsh`
  // }

  // Routing Logic
  return <>{routing}</>
}
