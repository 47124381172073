export const productNamesWithChild: any = {
  slideshow: {
    child: 'Photos',
    productIdName: 'slideShowId',
    moreInfoUrl: 'https://www.esstart.com/instant-spotlight/photo-slideshow',
  },
  storybook: {
    child: 'Pages',
    productIdName: 'storyBookId',
    moreInfoUrl:
      'https://www.esstart.com/storybook/digital-guest-book-with-live-quizzes',
  },
  seatingchart: {
    child: 'Records',
    productIdName: 'seatingChartId',
    moreInfoUrl: '',
  },
  'member-mailer': {
    child: '',
    productIdName: 'mailerId',
    moreInfoUrl: '',
  },
  songRequest: {
    child: 'Songs',
    productIdName: 'songRequestId',
    moreInfoUrl: '',
  },
  cameraRoll: {
    child: 'Media',
    productIdName: 'cameraRollId',
    moreInfoUrl: '',
  },
}
