import { configureStore, combineReducers, Reducer } from "@reduxjs/toolkit";
import reducerRegistry from "./reducerRegistry";

import "./coreReducers";

const store = configureStore({
  reducer: reducerRegistry.getReducers(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true,
});

reducerRegistry.setChangeListener((reducer: Record<string, Reducer>) => {
    store.replaceReducer(combineReducers(reducer));
});

export default store;
