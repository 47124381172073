import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { acceptInvite, getAllProductList } from '../../redux/products'
import loaderSpinner from '../../assets/images/esstart_spinner.gif'
import {
  Card,
  CardMedia,
  CardContent,
  PriceLable,
  CardFooter,
  Buttons,
} from 'esstart-components'
import './style.css'
import { productNamesWithChild } from '../../constant/productNames'
import { AuthContext, Loader } from 'esstart-components'
import { useNavigate } from 'react-router-dom'
import { DashboardModals } from '../dashboard/DashboardModals'

const CardComponent = ({
  title,
  descrition,
  image,
  price,
  enablePurchase,
  productId,
  moreInfoURL,
  distinctName,
}: any) => {
  const handleMoreInfo = () => {
    window.open(moreInfoURL, '_blank noreferrer noopener')
  }

  return (
    <>
      <Card>
        <CardMedia image={image} alt={title} />
        <CardContent className="h-100">
          <PriceLable cardLable={true} price={price} />
          <h5>{title}</h5>
          <div className="mt-2">
            <p>{descrition}</p>
          </div>
        </CardContent>
        <CardFooter>
          <div className="d-flex align-items-center mb-3">
            {/* <Buttons
              text="More Info"
              bgColor={`${!enableMoreInfo ? 'rgba(0, 0, 0, 0.12)' : '#fff'}`}
              color={`${
                !enableMoreInfo ? 'rgba(0, 0, 0, 0.26)' : 'rgb(255, 114, 0)'
              }`}
              borderRadius={true}
              boxShadow={!enableMoreInfo ? false : true}
              padding="8px 16px"
              onClick={handleMoreInfo}
              fontSize="12px"
              disabled={!enableMoreInfo && true}
            /> */}
            <Buttons
              text="Test Drive Now"
              bgColor={`${
                !enablePurchase ? 'rgba(0, 0, 0, 0.12)' : 'rgb(255, 114, 0)'
              }`}
              color={`${!enablePurchase ? 'rgba(0, 0, 0, 0.26)' : '#fff'}`}
              borderRadius={true}
              boxShadow={false}
              padding="8px 16px"
              fontSize="12px"
              className={`ms-2 test-drive-btn ${distinctName}-test-drive-btn`} // ${distinctName}-test-drive-btn class is for GTM
              onClick={() =>
                (window.location.href = `/dashboard?productId=${productId}`)
              }
            />
          </div>
          <p style={{ fontSize: '12px', color: 'rgb(181, 181, 181)' }}>
            * Your purchase is refundable within 30 days of purchase.
          </p>
        </CardFooter>
      </Card>
    </>
  )
}

export default function GamesAndActivities() {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()

  const gamesAndActivitiesData: any = useSelector(
    (state: any) => state.products
  )
  const settings: any = useSelector((state: any) => state.settings)
  const authWrapper: any = React.useContext(AuthContext)

  const [isLoader, setIsLoader] = useState(false)
  const [inviteCode, setInviteCode] = useState('')
  const [toggleNameModal, setToggleNameModal] = useState(false)

  useEffect(() => {
    !gamesAndActivitiesData.allProductList.length &&
      dispatch(getAllProductList())
  }, [])

  useEffect(() => {
    if (!gamesAndActivitiesData.allProductList.length) {
      setIsLoader(gamesAndActivitiesData.loading)
    } else {
      setIsLoader(false)
    }
  }, [isLoader, gamesAndActivitiesData.loading])

  const moderateEventClick = async (hostName?: string) => {
    if (
      authWrapper?.userInfoData?.name ||
      settings?.userInfoData?.name ||
      hostName
    ) {
      await dispatch(
        //@ts-ignore
        acceptInvite({
          name:
            authWrapper?.userInfoData?.name ||
            settings?.userInfoData?.name ||
            hostName,
          code: inviteCode,
        })
      )
      setInviteCode('')
      navigate('/dashboard')
    } else {
      setToggleNameModal(true)
    }
  }

  return (
    <div>
      <DashboardModals
        setToggleNameModal={setToggleNameModal}
        toggleNameModal={toggleNameModal}
        nameModalSubmitFn={moderateEventClick}
      />
      <div
        style={{ display: isLoader || settings?.loading ? 'flex' : 'none' }}
        className="loader"
      >
        {/* <img src={loaderSpinner} alt="Loader" /> */}
        <Loader />
      </div>
      <div className={`container my-3 games-activities-wrapper`}>
        <div className="row">
          {gamesAndActivitiesData.allProductList &&
          gamesAndActivitiesData.allProductList.length ? (
            gamesAndActivitiesData.allProductList
              .filter((prd: any) => prd?.distinctName !== 'member-mailer')
              .map((game: any, index: any) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-1 px-lg-2 px-md-1 mt-3 activity-card"
                  key={index}
                >
                  <CardComponent
                    title={game.displayTitle}
                    descrition={game.shortDesc1}
                    image={game.cardImgURL}
                    price={game.startingPrice}
                    productId={game._id}
                    enablePurchase={game.enablePurchase}
                    enableMoreInfo={game.enableMoreInfo}
                    moreInfoURL={
                      productNamesWithChild[game?.distinctName]?.moreInfoUrl
                    }
                    allowPurchase={game.allowPurchase}
                    distinctName={game?.distinctName}
                  />
                </div>
              ))
          ) : (
            <div style={{ display: isLoader ? 'none' : 'block' }}>
              <h4 style={{ textAlign: 'center', marginTop: '30px' }}>
                No Games and Activities were found!
              </h4>
            </div>
          )}
          <div className="col-lg-4 col-md-6 col-sm-1 px-lg-2 px-md-1 mt-3 activity-card">
            <div className="card-box moderator-card">
              <p>
                Have an invite code for co-host of an event? If yes, enter the
                code below.
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  moderateEventClick()
                }}
              >
                <input
                  type="text"
                  placeholder="Enter invitation code for co-host"
                  className="invite-code-inp"
                  value={inviteCode}
                  onChange={(e) => setInviteCode(e.target.value)}
                  required
                />
                <div className="d-flex justify-content-center gap-4">
                  <button
                    type="reset"
                    className="common-btn btn-secondary app-sec-btn"
                    onClick={() => setInviteCode('')}
                  >
                    CLEAR
                  </button>
                  <Buttons
                    text="Continue"
                    bgColor="#ff7200"
                    color="#fff"
                    borderRadius={true}
                    boxShadow={false}
                    padding="6px 20px"
                    fontSize="14px"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
