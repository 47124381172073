import React from 'react'
import leftArrow from '../../assets/images/orange-left-arrow.svg'
import './style.css'
import { useNavigate } from 'react-router-dom'
import { CommonQrWrapper } from '../dashboard'
import { useSelector, useDispatch } from 'react-redux'
import { Loader } from 'esstart-components'
import { getProducts } from '../../redux/products'

export const CommonQr = () => {
  const navigate = useNavigate()
  const dispatch: any = useDispatch()
  const settings: any = useSelector((state: any) => state.settings)
  const products: any = useSelector((state: any) => state.products)
  React.useEffect(() => {
    if (!products?.productsList?.length) {
      dispatch(getProducts())
    }
  }, [])
  return (
    <div className="common-qr-container">
      {settings?.loading && <Loader />}
      <header>
        <div className="flex-row gap-3">
          <img
            className="left-arrow cursor-pointer"
            src={leftArrow}
            alt="back"
            onClick={() => navigate('/dashboard')}
          />
          <h3 className="page-h3">Manage your personalized Master QR</h3>
        </div>

        <div className="breadcrumb">
          <span
            className="cursor-pointer"
            onClick={() => navigate('/dashboard')}
          >
            Dashboard
          </span>
          <span>/</span>
          <span>Master QR</span>
        </div>
      </header>
      <section className="mt-5">
        <div className="common-qr-page-comp">
          <CommonQrWrapper />
        </div>
      </section>
    </div>
  )
}
