import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Typography,
} from '@mui/material'
import './device-list.scss'
import { removeMyDevice } from '../../../redux/mydevices'
import loaderSpinner from '../../../assets/images/esstart_spinner.gif'
import EditDeviceImage from '../../../assets/images/device_edit.svg'
import EditDevice from '../edit-device/EditDevice'
import { Loader } from 'esstart-components'

export interface DeviceListProps {
  devices: {
    _id: string
    deviceIP: string
    brand: string
    deviceType: string
    model: string
    deviceName: string
  }[]
}
export const DeviceList = (props: DeviceListProps) => {
  const { devices } = props
  const dispatch = useDispatch<any>()
  const [openRemove, setOpenRemove] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [isDecideId, setDecideId] = useState('')
  const loading: any = useSelector((state: any) => state.myDevices.loading)
  const [openEdit, setOpenEdit] = useState(false)
  const [deviceId, setDeviceId] = useState('')
  const [deviceName, setDeviceName] = useState('')

  const removeSmartDevice = (deviceDetail: any) => {
    setOpenRemove(true)
    setDecideId(deviceDetail)
    // dispatch(removeMyDevice({deviceId: deviceDetail._id}))
  }

  const handleRemoveDevice = () => {
    setIsLoader(true)
    dispatch(removeMyDevice({ deviceId: isDecideId }))
    setOpenRemove(false)
    setIsLoader(false)
  }

  const handleClose = () => {
    setIsLoader(false)
    setOpenRemove(false)
  }

  const handleEditDevice = (device: string, deviceName: string) => {
    setDeviceId(device)
    setDeviceName(deviceName)
    setOpenEdit(true)
  }

  const closeEditDevice = () => {
    setOpenEdit(false)
    setDeviceId('')
  }

  return (
    <>
      <div
        style={{ display: isLoader || loading ? 'flex' : 'none' }}
        // className="loader"
      >
        {/* <img src={loaderSpinner} alt="Loader" /> */}
        <Loader />
      </div>
      {devices.map((device, index) => {
        return (
          device.deviceType !== 'browser' && (
            <Box
              component="div"
              key={index}
              className={`my-devices__device-box`}
            >
              <List className={`my-devices__device-detail-list`}>
                <ListItem className={`my-devices__device-detail`}>
                  <ListItemAvatar>
                    <div className={`my-devices__device-icon`}></div>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${
                      device?.deviceName
                        ? device?.deviceName
                        : device?.brand
                        ? device?.brand
                        : ''
                    } - ${device?.model ? device?.brand : ''} ${
                      device.deviceIP
                    }`}
                    secondary={`${device?.brand ? device?.brand : ''} ${
                      device.deviceType
                    }`}
                  />
                  <img
                    src={EditDeviceImage}
                    className="cursor-pointer"
                    alt="edit device"
                    onClick={() =>
                      handleEditDevice(device._id, device?.deviceName)
                    }
                  />
                </ListItem>
                <Divider
                  variant="inset"
                  className={`my-devices__divider`}
                  component="li"
                />

                <ListItem className={`my-devices__device-detail`}>
                  <ListItemText
                    className={`my-devices__paired-text`}
                    primary={`Your ${device?.brand ? device?.brand : ''} ${
                      device.deviceType
                    } is paired with Esstart`}
                  />
                  <ListItemAvatar>
                    <div
                      className={`my-devices__device-remove`}
                      onClick={() => removeSmartDevice(device._id)}
                      // onClick={() => removeSmartDevice(device)}
                    >
                      REMOVE
                    </div>
                  </ListItemAvatar>
                </ListItem>
              </List>
            </Box>
          )
        )
      })}
      <Dialog
        open={openRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete device?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveDevice}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <EditDevice
        open={openEdit}
        isDeviceName={deviceName}
        onClose={closeEditDevice}
        isDeviceId={deviceId}
      />
    </>
  )
}
