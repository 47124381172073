import styled from 'styled-components'
import BackgroundImage from '../../assets/images/backgroundImage.png'

export const SignInWrapper = styled.div`
  display: block !important;

  .pt-0 {
    padding-top: 0px !important;
  }
  .first-separator {
    margin-left: '50px';
    width: '160px';
  }

  .start-text {
    text-align: left;
    font: normal normal normal 32px/39px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
  }

  .dialog {
    padding-bottom: 0;
  }

  .start {
    text-align: left;
    font: normal normal normal 14px/18px Plus Jakarta Sans;
    margin: 1rem 0 0;
    color: #8d8d8d;

    &__login {
      color: #009fb7;
      text-decoration: none;
      padding-left: 6px;
    }
  }

  .anchor {
    text-decoration: none;
    color: #009fb7;
  }

  .auth {
    border: 2px solid black;
    border-radius: 20px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    &__start,
    &__start:hover {
      width: 198px;
      height: 40px;
      background: #ff7200 0% 0% no-repeat padding-box;
      border-radius: 20px;
      color: #ffffff;
    }
  }

  .stack {
    border: 1px solid #c8c8c8;
    border-radius: 22px;
    opacity: 1;
    border: unset;
  }

  .chip {
    width: 50%;
    max-width: 270px;
    height: 40px;
    border: 1px solid #c8c8c8;
    background-color: unset;
    border-radius: 22px;
    text-align: left;
    letter-spacing: 0px;
    color: black;
    font: normal normal normal 14px/19px Plus Jakarta Sans;
    cursor: pointer;
  }

  .errorMsg {
    padding-top: 10px;
    color: #d32f2f;
    font-size: 14px;
  }

  .strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    // padding-top: 25px;
    padding: 25px 0;
  }

  //css of hr tag

  .strike > span {
    position: relative;
    // display: inline-block;
    border: 1px solid grey;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    font-weight: 600;
    font-size: 15px;
    padding: 3px 5px 4px 5px;
  }

  .strike > span:before,
  .strike > span:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #d0d0d0;
  }

  .strike > span:before {
    right: 100%;
    width: 180px;
  }

  .strike > span:after {
    left: 100%;
    width: 180px;
  }

  // ends here

  .input-field {
    padding: 15px 0 0 0;
    font: normal normal normal 14px/18px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
  }

  .font {
    text-align: left;
    font: normal normal normal 16px/19px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }

  .textField {
    margin-top: 10px;
  }

  .MuiChip-avatar {
    width: unset;
    height: unset;
  }

  .forgotPwd {
    color: #009fb7;
    cursor: pointer;
  }
  .forgotPwd a {
    display: flex;
    justify-content: end;
    color: #009fb7;
    cursor: pointer;
    text-decoration: none;
  }

  .remove_btn_hover: hover {
    background-color: #ff7200 !important;
  }

  .login_page {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0;
  }

  .page_banner {
    padding: 1.25rem;
    .tab-view {
      align-self: stretch;
      font-weight: 500;
      background-color: #f1f1f1;
      border-radius: 1.875rem;
      font-size: 1.563rem;
      padding: 4px;
      margin: 0 1.375rem;
      div {
        flex-grow: 1;
      }
      div:first-child {
        padding: 9px;
        background-color: #ffffff;
        border-radius: 1.875rem;
      }
      div:nth-child(2) {
        padding: 9px;
      }
    }
    .full-strike {
      width: 100%;
      border-bottom: 1px solid #0000001f;
      margin: 1.25rem 0 1.375rem;
    }
  }

  .page_banner .title {
    font: normal normal 600 32px/40px Plus Jakarta Sans;
    color: #202020;
    margin: 0;
  }

  .page_banner .subtitle {
    font: normal normal normal 17px/20px Plus Jakarta Sans;
    color: #202020;
    margin: 0;
  }

  .login_form {
    max-width: 540px;
    width: 100%;
    background-color: #fff;
  }

  .login_page h5 {
    font: normal normal normal 49px/73px Plus Jakarta Sans;
    margin-top: 60px;
  }

  .login_page h5 p {
    margin: 0;
    span:first-child {
      color: #049849;
    }
    span:nth-child(2) {
      color: #ff7200;
    }
  }

  .website_logo {
    width: 50%;
  }

  .login_menu {
    margin-bottom: 145px;
    display: flex;
    align-items: center;
  }

  .login_menu button svg {
    font-size: 13px;
    color: #ff7200;
  }

  .product_box {
    margin-top: 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 5px #00000017;
    width: 100%;
    max-width: 548px;
  }

  .product_box h3 {
    font: normal normal bold 20px/24px Plus Jakarta Sans;
    margin: 15px 15px 0 15px;
  }

  .product_box p {
    font: normal normal normal 15px/19px Plus Jakarta Sans;
    padding: 5px 15px 15px 15px;
    color: #202020;
  }

  .product_box img {
    width: 100%;
  }

  .login_form form {
    padding: 0 20px 20px;
  }

  .login_background {
    background-image: url(${BackgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  .content_part {
    width: 100%;
    max-width: calc(100% - 560px);
  }

  .token-popup {
    textarea {
      border: 1px solid;
      border-radius: 4px;
      resize: none;
      width: 100%;
      padding: 14px 16px;
    }
    button {
      padding: 0.5rem 2rem;
    }
  }

  @media (max-width: 1023px) {
    .login_page {
      display: block;
    }
    .content_part,
    .login_form {
      max-width: 600px;
      margin: auto;
    }
    .login_form {
      margin-top: 30px;
    }
    .login_menu {
      margin-bottom: 60px;
    }
    .product_box {
      margin-right: 0px;
      max-width: 100%;
    }
  }

  @media (max-width: 768px) {
    .login_page {
      padding: 30px 0;
    }

    .content_part,
    .login_form {
      max-width: 100%;
    }

    .login_menu {
      margin-bottom: 30px;
    }

    .stack {
      display: block;
      text-align: center;
    }
  }
  @media (max-width: 640px) {
    .chip {
      margin: 15px 0 0;
      width: 100%;
      max-width: 100%;
    }
    .login_menu {
      display: none;
    }
    form {
      display: flex;
      flex-direction: column;
      .social-wrapper {
        order: 2;
      }
      .strike {
        order: 1;
      }
      .privacy_policychange {
        order: 3;
      }
    }
  }
  @media screen and (max-width: 350px) {
    .page_banner {
      .title {
        font: 600 28px / 36px 'Plus Jakarta Sans';
      }
      .tab-view {
        font-size: 1.2rem;
      }
      .page_banner {
        padding-bottom: 0;
      }
    }
    .login_form {
      margin-top: 16px;
    }
  }
  @media screen and (max-width: 1050px) {
    .hide-for-small-screen {
      display: none;
    }
  }
`
