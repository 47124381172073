import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  IconButton,
  Typography,
  DialogContent,
  Dialog,
  TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { confirmUser } from '../../redux/auth'
import { SetAccountWrapper } from '../signup/style'
import { DialogWrapper, LoaderWrapper } from './style'
import email from '../../assets/images/email.png'
import loader from '../../assets/images/esstart_spinner.gif'
import { useFormik } from 'formik'
import { getCookie } from 'esstart-components'

interface IConfirmAccount {
  email?: string
}

export const ConfirmAccount: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const username = getCookie('username')
  // const username = useSelector((state: any) =>  state.auth.username);
  const verifyStatus = useSelector((state: any) => state.auth.verifyStatus)
  const [userConfirmation, setUserConfirmation] = useState<boolean | null>(null)
  const [validateEmail, toggleValidateEmail] = useState<boolean | null>(false)
  const [error, setError] = useState(null)
  const [searchParams] = useSearchParams()

  const confirmAccountValidate = (values: any) => {
    const errors = {} as IConfirmAccount
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i

    if (!values.email) {
      errors.email = 'Required'
    } else if (!emailPattern.test(values.email)) {
      errors.email = 'Valid Email Required'
    }
    return errors
  }

  useEffect(() => {
    if (verifyStatus === 401) {
      window.location.href = `${window.location.origin}/error`
      // navigate('/error');
    }
  }, [verifyStatus])

  const {
    handleChange,
    handleSubmit,
    errors,
    handleBlur,
    touched,
    setFieldError,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validate: confirmAccountValidate,
    onSubmit: (values: any) => {
      const { email } = values
      if (email) {
        verifyCode(email)
      }
    },
  })

  const handleValidateEmail = () => {
    toggleValidateEmail(false)
  }

  const confirmationCode: string | null = searchParams.get('confirmationCode')

  const verifyCode = async (email?: string) => {
    const windowUrl = window.location.search
    const queryParams = new URLSearchParams(windowUrl)
    const productId = queryParams.get('testProduct') || ''
    if (confirmationCode && (username || email)) {
      const response = await dispatch(
        confirmUser({ username: username || email, confirmationCode })
      )
      if (response.type === 'auth/confirmUser/fulfilled') {
        const redirectProductId = productId
          ? `/dashboard?productId=${productId}`
          : '/dashboard'
        window.location.href = `${window.location.origin}${redirectProductId}`
        navigate(redirectProductId)
      } else {
        setUserConfirmation(false)
        navigate('/login')
        // TODO: Show some UI that says that the confirmationCode is not correct
      }
      if (response.type === 'auth/confirmUser/rejected') {
        setFieldError('email', response.payload)
        setError(response.payload)
      }
    }
  }

  useEffect(() => {
    if (!username) {
      toggleValidateEmail(true)
    } else {
      verifyCode()
    }
  }, [])

  const redirectToDashboard = () => {
    window.location.href = window.location.origin
  }

  if (validateEmail) {
    return (
      <Dialog open={validateEmail}>
        <form onSubmit={handleSubmit}>
          <DialogWrapper>
            <DialogContent>
              <div className="heading">
                Email Validation
                <IconButton>
                  <CloseIcon onClick={handleValidateEmail} />
                </IconButton>
              </div>
              <p className="first-para">
                Enter your registered email address with Esstart for Validate
              </p>

              <span className="span">Enter Email Address </span>

              <div>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  className="text-field"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.email && !!touched.email}
                />
                {errors.email && touched.email ? (
                  <div className="errorMsg">{errors.email as string}</div>
                ) : null}
              </div>

              <div className="button-container">
                <Button
                  className="button-container__start remove_btn_hover"
                  type="submit"
                >
                  {' '}
                  Verify
                </Button>
              </div>
            </DialogContent>
          </DialogWrapper>
        </form>
      </Dialog>
    )
  }

  if (userConfirmation === null) {
    // TODO: Show a good UI that indicates the user that the process of confirming the user is going on
    return (
      <>
        {/* <PublicNavigation /> */}
        {/* <Header /> */}
        <LoaderWrapper>
          {/* <div className='loader'></div> */}
          <Typography
            style={{ display: 'flex', marginBottom: '1px' }}
            className="loaderMain"
            component="div"
          >
            <img src={loader} alt="Loader" />
          </Typography>
          <div className="text">Your Account is Getting Ready...</div>
        </LoaderWrapper>
      </>
    )
  }

  return (
    <div>
      {/* <PublicNavigation /> */}
      {/* <Header /> */}
      <Dialog open={userConfirmation}>
        <SetAccountWrapper>
          <DialogContent>
            <div className="image">
              <img src={email} alt="Email Logo" />
            </div>
            <span className="heading">
              <p>Yes, Your Account is Set Now !</p>
            </span>
            <div className="button-container">
              <Button
                className="button-container__start remove_btn_hover"
                onClick={redirectToDashboard}
              >
                {' '}
                START TEST DRIVE{' '}
              </Button>
            </div>
          </DialogContent>
        </SetAccountWrapper>
      </Dialog>
    </div>
  )
}
