import styled from "styled-components";

export const ForgotPasswordWrapper = styled.div`
 h2 {
  font-size: 2rem;
  font-weight: 400;
 }
 p {
  font-size: 1rem;
 }
 .modal_content .forgot-pwd-form{
  padding: 0;
 }
 .close-icon {
  margin-left: auto;
  float: right;
  cursor: pointer;
 }
 .verify-email-container {
  .heading {
    font-size: 2.1rem;
  }
  .resend-email{
    cursor: pointer;
    color: #009FB7;
  }
 }
`;