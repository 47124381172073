import React from 'react'
import {
  TextField,
  Dialog,
  DialogTitle,
  useMediaQuery,
  DialogContent,
  Box,
  DialogActions,
  Button,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import '../add-device/add-device.scss'
import { useDispatch, useSelector } from 'react-redux'
import { editDevice, getMyDevices } from '../../../redux/mydevices'
import { useFormik } from 'formik'
import { deviceNameValidate } from './../../../validation'

export interface DialogProps {
  open: boolean
  isDeviceId: string
  isDeviceName: string
  onClose: () => void
}

const EditDevice = (props: DialogProps) => {
  const { open, isDeviceId, isDeviceName, onClose } = props
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch<any>()
  const { responseStatus } = useSelector((store: any) => store.myDevices)

  const handleClose = () => {
    onClose()
  }

  const { handleChange, touched, values, errors, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        deviceName: isDeviceName || '',
      },
      enableReinitialize: true,
      validate: deviceNameValidate,
      onSubmit: async (values: any) => {
        dispatch(
          editDevice({ deviceName: values.deviceName, deviceId: isDeviceId })
        )
      },
    })

  React.useEffect(() => {
    if (responseStatus === 'success') {
      dispatch(getMyDevices())
    }
  }, [dispatch, responseStatus])

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="responsive-dialog-title">
            <span style={{ fontSize: '32px' }} onClick={handleClose}></span>{' '}
            <span>Edit Smart Device</span>
          </DialogTitle>
          <DialogContent style={{ width: '550px', overflow: 'hidden' }}>
            <Box component="div" sx={{ marginTop: '33px' }}>
              <div className="input-field">
                <span className="deviceNmaeLable">Enter device Name</span>
                <TextField
                  fullWidth={true}
                  type="text"
                  placeholder="Enter Device Name"
                  value={values.deviceName}
                  id="deviceName"
                  // defaultValue={isDeviceName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {/* // error={!!errors.deviceName && !!touched.deviceName} */}
                {errors.deviceName && touched.deviceName ? (
                  <div className="errorMsg">{errors.deviceName as string}</div>
                ) : null}
                {/* </FormGroup> */}
                {/* <TextField style={{ paddingTop: "10px" }}
              fullWidth
              id="deviceName"
              type="text"
              className='textField'
              value={deviceName}
              defaultValue={isDeviceName}
              onChange={(e: any) => handleOnChange(e)}
            /> */}
              </div>
            </Box>
          </DialogContent>
          <DialogActions
            id={`dialogBtnActions`}
            style={{ marginBottom: '20px', marginTop: '35px' }}
          >
            <Button type="submit">Edit Device</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default EditDevice
