
import { Reducer } from "@reduxjs/toolkit"

export class ReducerRegistry {
    _emitChange: Function | null;
    _reducers: Record<string, Reducer> = {}
    constructor() {
        this._emitChange = null;
        this._reducers = {};
    }

    getReducers() {
        return { ...this._reducers };
    }

    register(name: string, reducer: any) {
        this._reducers = { ...this._reducers, [name]: reducer };
        this._emitChange?.call(this, this.getReducers());

    }

    setChangeListener(listener: Function) {
        this._emitChange = listener;
    }
}

const reducerRegistry = new ReducerRegistry();
export default reducerRegistry;
