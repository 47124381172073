import styled from 'styled-components'
import { alpha, styled as muiStyle } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import BackgroundImage from '../../assets/images/backgroundImage.png'

export const SignUpWrapper = styled.div`
  display: block !important;
  .first-separator {
    margin-left: '50px';
    width: '160px';
  }

  .start-text {
    text-align: left;
    font: normal normal normal 32px/39px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
  }

  .dialog {
    padding-bottom: 0;
  }

  .start {
    text-align: left;
    font: normal normal normal 16px/19px Plus Jakarta Sans;
    color: #202020;
    padding: 17px 0 17px 0;

    &__login {
      color: #009fb7;
      text-decoration: none;
      padding-left: 6px;
    }
  }

  .anchor {
    text-decoration: none;
    color: #009fb7;
  }

  .auth {
    border: 2px solid black;
    border-radius: 20px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    &__start {
      width: 198px;
      height: 40px;
      background: #ff7200 0% 0% no-repeat padding-box;
      border-radius: 20px;
      color: #ffffff;
    }
  }

  .stack {
    border: 1px solid #c8c8c8;
    border-radius: 22px;
    opacity: 1;
    border: unset;
  }

  .chip {
    width: 50%;
    max-width: 270px;
    height: 40px;
    border: 1px solid #c8c8c8;
    background-color: unset;
    border-radius: 22px;
    text-align: left;
    letter-spacing: 0px;
    color: black;
    font: normal normal normal 14px/19px Plus Jakarta Sans;
    cursor: pointer;
  }

  .errorMsg {
    padding-top: 10px;
    color: #d32f2f;
    font-size: 14px;
  }

  .strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    // padding-top: 25px;
    padding: 25px 0;
  }

  //css of hr tag

  .strike > span {
    position: relative;
    // display: inline-block;
    border: 1px solid grey;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    font-weight: 600;
    font-size: 15px;
    padding: 3px 5px 4px 5px;
  }

  .strike > span:before,
  .strike > span:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #d0d0d0;
  }

  .strike > span:before {
    right: 100%;
    width: 180px;
  }

  .strike > span:after {
    left: 100%;
    width: 180px;
  }

  // ends here

  .input-field {
    padding: 15px 0 0 0;
    font: normal normal normal 14px/18px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
  }

  .font {
    text-align: left;
    font: normal normal normal 16px/19px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }

  .textField {
    margin-top: 15px;
  }

  .MuiChip-avatar {
    width: unset;
    height: unset;
  }

  .forgotPwd {
    display: flex;
    justify-content: end;
    color: #009fb7;
    cursor: pointer;
  }

  .remove_btn_hover: hover {
    background-color: #ff7200 !important;
  }

  .login_page {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0;
  }

  .login_form {
    max-width: 540px;
    width: 100%;
    background-color: #fff;
  }

  .login_page h5 {
    font-size: 40px;
    margin-top: 60px;
  }

  .login_page h5 p {
    margin: 0;
    color: #ff7200;
  }

  .website_logo {
    width: 50%;
  }

  .login_menu {
    margin-bottom: 145px;
    display: flex;
  }

  .login_menu button svg {
    font-size: 13px;
    color: #ff7200;
  }

  .product_box {
    margin-top: 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 5px #00000017;
    width: 100%;
    max-width: 548px;
  }

  .product_box h3 {
    font: normal normal bold 20px/24px Plus Jakarta Sans;
    margin: 15px 15px 0 15px;
  }

  .product_box p {
    font: normal normal normal 15px/19px Plus Jakarta Sans;
    padding: 5px 15px 15px 15px;
    color: #202020;
  }

  .product_box img {
    width: 100%;
  }

  .login_form form {
    padding: 20px;
  }

  .login_background {
    background-image: url(${BackgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  .content_part {
    width: 100%;
    max-width: calc(100% - 560px);
  }

  @media (max-width: 1023px) {
    .login_page {
      display: block;
    }
    .content_part,
    .login_form {
      max-width: 600px;
      margin: auto;
    }
    .login_form {
      margin-top: 60px;
    }
    .login_menu {
      margin-bottom: 60px;
    }
    .product_box {
      margin-right: 0px;
      max-width: 100%;
    }
  }

  @media (max-width: 768px) {
    .login_page {
      padding: 30px 0;
    }

    .content_part,
    .login_form {
      max-width: 100%;
    }

    .login_menu {
      margin-bottom: 30px;
    }

    .stack {
      display: block;
      text-align: center;
    }
  }
  @media (max-width: 640px) {
    .chip {
      margin: 15px 0 0;
      width: 100%;
      max-width: 100%;
    }
  }
`

export const EmailWrapper = styled.div`
.button-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
  
    &__start {
        width: 198px;
        height: 40px;
        background: #FF7200 0% 0% no-repeat padding-box;
        border-radius: 20px;
        color: #FFFFFF;
       
    }
  }
  
  .image {
    display: flex;
    justify-content: center;
  }
  
  .heading {
    display: flex;
    justify-content: center;
    font: normal normal normal 34px/42px Plus Jakarta Sans;
    padding-top: 20px;
  }
  
  .first-para {
    text-align: center;
    font: normal normal normal 16px/27px Plus Jakarta Sans;
  }
  
  .last-para {
    text-align: center;
    padding-top: 20px;
    font: normal normal normal 16px/19px Plus Jakarta Sans;
  }
  .anchor {
    text-decoration: none;
    color: #009FB7;
  }
  // ends here
  
  .input-field {
    padding: 15px 0 0 0;
    font: normal normal normal 14px/18px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
  }
  
  .font {
    text-align: left;
    font: normal normal normal 16px/19px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }
  
  .textField {
    margin-top: 15px;
  }
  
  .MuiChip-avatar {
    width: unset;
    height: unset;
  }
  
  .forgotPwd {
    display: flex;
    justify-content: end;
    color: #009FB7;
    cursor: pointer;
  }
  
  .remove_btn_hover: hover {
    background-color: #FF7200 !important;
  }
  
  .login_page {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0;
  }
  
  .login_form {
    max-width: 540px;
    width: 100%;
    background-color: #fff;
  }
  
  .login_page h5 {
    font-size: 40px;
    margin-top: 60px;
  }
  
  .login_page h5 p {
    margin: 0;
    color: #FF7200;
  }
  
  .website_logo {
    width: 50%;
  }
  
  .login_menu {
    margin-bottom: 145px;
    display: flex;
  }
  
  .login_menu button svg {
    font-size: 13px;
    color: #FF7200;
  }
  
  .product_box {
    margin-top: 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 5px #00000017;
    width: 100%;
    max-width: 548px;
  }
  
  .product_box h3 {
    font: normal normal bold 20px/24px Plus Jakarta Sans;
    margin: 15px 15px 0 15px;
  }
  
  .product_box p {
    font: normal normal normal 15px/19px Plus Jakarta Sans;
    padding: 5px 15px 15px 15px;
    color: #202020;
  }
  
  .product_box img {
    width: 100%;
  }
  
  .login_background {
    background-image: url(${BackgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }
  
  .content_part {
    width: 100%;
    max-width: calc(100% - 560px);
  }
  
  @media (max-width: 1023px) {
    .login_page {
      display: block;
    }
    .content_part,
    .login_form {
      max-width: 600px;
      margin: auto;
    }
    .login_form {
      margin-top: 60px;
    }
    .login_menu {
      margin-bottom: 60px;

    .last-para {
        display: inherit;
        text-align: center;
        font: normal normal normal 16px/19px Plus Jakarta Sans;

        .anchor {
            text-decoration: none;
            color: #009FB7;
        }
    }
    .product_box {
      margin-right: 0px;
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .login_page {
      padding: 30px 0;
    }
  
    .content_part,
    .login_form {
      max-width: 100%;
    }
  
    .login_menu {
      margin-bottom: 30px;
    }
  
    .stack {
      display: block;
      text-align: center;
    }
  }
  @media (max-width: 640px) {
    .chip {
      margin: 15px 0 0;
      width: 100%;
      max-width: 100%;
    }
  }
`

export const SetAccountWrapper = styled.div`
  .button-container {
    display: flex;
    justify-content: center;

    &__start {
      width: 198px;
      height: 40px;
      background: #ff7200 0% 0% no-repeat padding-box;
      border-radius: 20px;
      color: #ffffff;
    }
  }

  .image {
    display: flex;
    justify-content: center;
  }

  .heading {
    display: flex;
    justify-content: center;
    font: normal normal normal 34px/42px Plus Jakarta Sans;
    padding: 23px 0 20px 0;
  }
`

export const BootstrapInput = muiStyle(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))
