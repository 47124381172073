import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get, deletek, axiosAuth } from 'esstart-components'
import Cookies from 'js-cookie'

export const myDevicesSliceName = 'myDevices'

const deviceUrl = `/my/v1/devices`

const initialState = {
  devicesList: [],
  loading: false,
  creatingDevice: false,
  responseStatus: '',
  message: '',
}

interface AddDevicePayload {
  handshakeCode: string
}

interface EditDevicePayload {
  deviceName: string
  deviceId: string
}

const getHeaders = () => ({
  headers: {
    Authorization: Cookies.get('accessToken') as string,
    Accept: 'application/json',
  },
})

export const removeMyDevice = createAsyncThunk(
  'device/remove',
  async (args: any) => {
    const { deviceId } = args

    const config = {
      data: {
        deviceId,
      },
    }
    const res = await deletek(deviceUrl, config)
    return res.data
  }
)

export const getMyDevices = createAsyncThunk('device/getAll', async () => {
  const res = await get(deviceUrl)
  return res?.data?.data || []
})

export const clearMessage = createAsyncThunk(
  'device/clearMessage',
  async () => {
    const status = ''
    return status
  }
)

export const addDevice = createAsyncThunk(
  'device/add',
  async (args: AddDevicePayload, { rejectWithValue }) => {
    try {
      const url = '/device-handshake'
      const res = await axiosAuth.post(url, { ...args }, getHeaders())
      return { ...res.data }
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const editDevice = createAsyncThunk(
  'device/edit',
  async (args: EditDevicePayload, { rejectWithValue }) => {
    try {
      const url = '/device-update-name'
      const { data } = await axiosAuth.post(url, { ...args }, getHeaders())
      return { ...data, ...args }
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const myDevicesSlice = createSlice({
  name: myDevicesSliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addDevice.pending, (state) => {
      state.creatingDevice = true
      state.responseStatus = ''
      state.message = ''
    })
    builder.addCase(addDevice.rejected, (state, action: any) => {
      state.creatingDevice = false
      state.responseStatus = action.payload.status
      state.message = action.payload.message
    })
    builder.addCase(addDevice.fulfilled, (state, action) => {
      state.creatingDevice = false
      state.responseStatus = action.payload.status
      state.message = action.payload.message
    })
    builder.addCase(getMyDevices.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getMyDevices.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getMyDevices.fulfilled, (state, action) => {
      state.loading = false
      state.devicesList = action.payload
    })
    builder.addCase(editDevice.pending, (state) => {
      state.loading = true
      state.responseStatus = ''
    })
    builder.addCase(editDevice.rejected, (state, action: any) => {
      state.loading = false
      state.responseStatus = action.payload.status
    })
    builder.addCase(editDevice.fulfilled, (state, action) => {
      state.loading = false
      // state.responseStatus = action.payload.status
      const indexOfDevice = state.devicesList.findIndex(
        (i: any) => i?._id === action.payload.deviceId
      )
      if (indexOfDevice !== -1) {
        //@ts-ignore
        state.devicesList = [
          ...state.devicesList.slice(0, indexOfDevice),
          {
            //@ts-ignore
            ...state.devicesList[indexOfDevice],
            deviceName: action.payload.deviceName,
          },
          ...state.devicesList.slice(indexOfDevice + 1),
        ]
      }
    })
    builder.addCase(clearMessage.fulfilled, (state) => {
      state.responseStatus = ''
      state.message = ''
    })
    builder.addCase(removeMyDevice.pending, (state) => {
      state.loading = true
    })
    builder.addCase(removeMyDevice.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(removeMyDevice.fulfilled, (state, action) => {
      state.loading = false
      state.devicesList.splice(
        state.devicesList.findIndex(
          (device: any) => device._id === action.payload.data.deviceId
        ),
        1
      )
    })
  },
})

export const myDeviceReducer = myDevicesSlice.reducer

// ReducerRegistry.register(myDevicesSliceName, myDeviceReducer);
