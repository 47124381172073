import React from 'react'
import { Chip, Stack } from '@mui/material'
import google from '../../assets/images/google.svg'
import facebook from '../../assets/images/facebook.svg'

export const SocialLogin = () => {
  const onGoogleLogin = () => {
    window.open(process.env.REACT_APP_GOOGLE_LOGIN_URL, '_self')
  }

  const onFaceBookLogin = () => {
    window.open(process.env.REACT_APP_FACEBOOK_LOGIN_URL, '_self')
  }

  const isSignupPage = window.location.pathname === '/signup'

  return (
    <>
      <Stack direction="row" spacing={1} className="stack">
        <Chip
          label="Continue With Google"
          className={`chip ${!!isSignupPage ? 'signup-started' : ''}`}
          onClick={onGoogleLogin}
          avatar={<img alt="Google" src={google} />}
        />
        <Chip
          label="Continue With Facebook"
          className={`chip ${!!isSignupPage ? 'signup-started' : ''}`}
          onClick={onFaceBookLogin}
          avatar={<img alt="Facebook" src={facebook} />}
        />
      </Stack>
    </>
  )
}

export default SocialLogin
