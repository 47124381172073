import React, { useEffect, useState } from 'react'
// import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  getProducts,
  getProduct,
  createProduct,
  setShowUnUsedEvents,
  getAllProductList,
  acceptInvite,
} from '../../redux/products'
import loaderSpinner from '../../assets/images/esstart_spinner.gif'
import { getCookie, Buttons, Loader } from 'esstart-components'
import './dashboard.css'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import CloseIcon from '../../assets/images/noun-close.svg'
import { DashboardModals } from './DashboardModals'
import { DashboardCard } from './DashboardCard'
import { editUserInfo } from '../../redux/settings'
import { AuthContext } from 'esstart-components'
import { resendEmail } from '../../redux/auth'
import toast from 'react-hot-toast'
import { productNamesWithChild } from '../../constant/productNames'
import { getAllStrProducts, purchaseProduct } from '../../redux/payment'
import GamesAndActivities from '../gamesAndActivity'
import QRCode from 'react-qr-code'
import { FormControl, Select, MenuItem } from '@mui/material'
import { errorToast, successToast } from '../toasts'
import BlueTick from '../../assets/images/blue-tick-icon.svg'
import Cookies from 'js-cookie'

export const Dashboard = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const productId = searchParams.get('productId')

  const [addingProduct, setAddingProduct] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)
  const [removeUrl, setRemoveUrl] = useState('')
  const [isLoader, setIsLoader] = useState(false)
  const [isNotRemoveSlide, setNotRemoveSlide] = useState(false)
  const [showToast, setShowToast] = useState('')
  const [removeMsg, setRemoveMsg] = useState('')
  const [toggleNameModal, setToggleNameModal] = useState(false)
  const [createNewSlideshowModal, toggleCreateNewSlideshowModal] = useState('')

  const products: any = useSelector((state: any) => state.products)
  const settings: any = useSelector((state: any) => state.settings)
  const authWrapper: any = React.useContext(AuthContext)

  const unUsedFilteredEvents = products?.productsList?.filter((event: any) => {
    const showDate = new Date(event?.showDate)
    const currentDate = new Date()
    const differenceInTime = currentDate.getTime() - showDate.getTime()
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24))
    return (
      ((differenceInDays < 11 || !event?.showDate) &&
        event?.distinctName !== 'member-mailer') ||
      event?.productStatus === 'test'
    )
  })
  const paymentSlice = useSelector((store: any) => store?.payments)
  const membershipPlan = paymentSlice?.strProductsList?.find(
    (prd: any) =>
      prd?.metadata?.distinctName === 'membership' &&
      prd?.priceMetadata?.latest === 'true'
  )

  useEffect(() => {
    ;(async () => {
      if (productId && !addingProduct) {
        dispatch(getProduct({ productId: productId }))
      } else {
        const response = await dispatch(getProducts())
        if (
          !!getCookie('productId') &&
          !!getCookie('planId') &&
          !!getCookie('from')
        ) {
          // to check if user has come from landing page and wants to buy the product
          redirectionToPurchase(
            response.payload.find((i: any) => i.productStatus === 'test')
          )
        }
      }
    })()
  }, [productId, addingProduct, dispatch])

  useEffect(() => {
    if (products?.loading || products?.creatingProduct) {
      setIsLoader(true)
    } else {
      setIsLoader(false)
    }
  }, [products])

  const addModeratorProduct = async () => {
    const moderatorCode = getCookie('mCode')
    if (!!moderatorCode) {
      if (authWrapper?.userInfoData?.name) {
        await dispatch(
          acceptInvite({
            name: authWrapper?.userInfoData?.name,
            code: moderatorCode,
          })
        )
        navigate('/dashboard')
        Cookies.remove('mCode')
      } else {
        console.error('User name is not found')
      }
    }
  }

  useEffect(() => {
    dispatch(
      getAllStrProducts({
        hasMembership: !!authWrapper?.userInfoData?.hasMembership,
      })
    )
    addModeratorProduct()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (productId && products?.selectedProduct && !addingProduct) {
        setAddingProduct(true)
        const res = await dispatch(
          createProduct({
            createAPIurl: products.selectedProduct?.createAPIurl?.url,
          })
        )
        if (!!res?.payload?.data?.data?._id) {
          const data = {
            _id: res?.payload?.data?.data?._id,
            prodcutMetadata: {
              manageURL: res?.payload?.data?.data?.manageURL,
            },
          }
          handleManageRedirection(data)
        }
      } else {
        if (!!authWrapper?.userInfoData?.firstVisit) {
          dispatch(editUserInfo({ firstVisit: false }))
          authWrapper.setUserInfoData((prev: any) => ({
            ...prev,
            firstVisit: false,
          }))
          // navigate('/allproducts')
        }
      }
      if (addingProduct && !products.selectedProduct) {
        if (searchParams.has('productId')) {
          searchParams.delete('productId')
          setSearchParams(searchParams)
        }
      }
    })()
  }, [
    productId,
    products?.selectedProduct,
    searchParams,
    addingProduct,
    dispatch,
  ])

  useEffect(() => {
    if (products?.createErrorResponse && productId) {
      setNotRemoveSlide(true)
    }
  }, [products?.createErrorResponse])

  useEffect(() => {
    setShowToast(authWrapper?.userInfoData?.isEmailVerified)
  }, [authWrapper?.userInfoData?.isEmailVerified])

  const handleManageRedirection = (productData: any) => {
    window.location.href = productData?.relation
      ? `${productData?.prodcutMetadata?.manageURL}/${productData.relation}/${productData._id}`
      : `${productData?.prodcutMetadata?.manageURL}/${productData._id}`
  }

  const redirectionToPurchase = (productData: any) => {
    window.location.href = `${productData?.prodcutMetadata?.manageURL}/${productData._id}/purchase-options`
  }

  const handleOpenRemoveModal = (productData: any) => {
    const {
      _id,
      prodcutMetadata: { distinctName, createAPIurl, totalEntries },
    } = productData

    setOpenRemove(true)
    setNotRemoveSlide(false)
    setRemoveUrl(
      `${createAPIurl?.url}?${productNamesWithChild[distinctName]?.productIdName}=${_id}`
    )
  }

  useEffect(() => {
    if (isNotRemoveSlide) {
      setTimeout(() => {
        setNotRemoveSlide(false)
        setRemoveMsg('')
        if (productId && searchParams.has('productId')) {
          searchParams.delete('productId')
          setSearchParams(searchParams)
        }
      }, 3000)
    }
  }, [isNotRemoveSlide])

  useEffect(() => {
    dispatch(getAllProductList())
  }, [])

  // const addInstantSpotlight = () => {
  //   window.location.href = `/dashboard?productId=${
  //     gamesAndActivitiesData.allProductList.filter(
  //       (i: any) => i.distinctName === 'slideshow'
  //     )[0]._id
  //   }`
  // }

  const handleVerifyEmail = async () => {
    const userName = getCookie('username')
    if (userName) {
      await dispatch(resendEmail({ userName }))
      toast('Verification link sent to your email !', {
        style: {
          backgroundColor: '#d1e7dd',
          color: '#0f5132',
        },
      })
    }
  }

  const purchasePlan = async () => {
    const checkoutSessionResponse = await dispatch(
      purchaseProduct({
        strCId: authWrapper?.userInfoData?.strCId,
        username: authWrapper?.userInfoData?.email,
        line_items: [{ price: membershipPlan?.priceId, quantity: 1 }],
        success_url: `${process.env.REACT_APP_WEBAPP_URL}dashboard?payment=success`,
        cancel_url: `${process.env.REACT_APP_WEBAPP_URL}dashboard`,
        distinctName: 'membership',
        // productId: slideshow[0]._id,
        // planId: filteredPlan?.metadata?.planId,
        paymentMode: 'subscription',
        isCustomerSubscribed: authWrapper?.userInfoData?.hasMembership,
        //@ts-ignore
        tolt_referral: window?.tolt_referral,
      })
    )
    window.open(checkoutSessionResponse?.payload?.payload?.data?.url, '_self')
  }

  // const onQrDownload = () => {
  //   const svg = document.getElementById('QRCode')!
  //   const svgData = new XMLSerializer().serializeToString(svg)
  //   const canvas = document.createElement('canvas')
  //   const ctx = canvas.getContext('2d')!
  //   const img = new Image()
  //   img.onload = () => {
  //     // 600 dimensions so that qr can have white margins
  //     canvas.width = 600
  //     canvas.height = 600
  //     ctx.fillStyle = 'white'
  //     ctx.fillRect(0, 0, 600, 600)
  //     ctx.drawImage(img, 50, 50)
  //     const pngFile = canvas.toDataURL('image/png')
  //     const downloadLink = document.createElement('a')
  //     downloadLink.download = 'QRCode'
  //     downloadLink.href = `${pngFile}`
  //     downloadLink.click()
  //   }
  //   img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
  // }

  // const onEnableCommonQr = async () => {
  //   const res = await dispatch(editUserInfo({ enableCommonQr: true }))
  //   if (res?.payload?.data?.status === 'success') {
  //     authWrapper.setUserInfoData((prev: any) => ({
  //       ...prev,
  //       enableCommonQr: true,
  //     }))
  //     successToast('Master QR activated successfully!')
  //   } else {
  //     errorToast('Something went wrong!')
  //   }
  // }

  return (
    <>
      <DashboardModals
        setToggleNameModal={setToggleNameModal}
        createNewSlideshowModal={createNewSlideshowModal}
        toggleCreateNewSlideshowModal={toggleCreateNewSlideshowModal}
        setIsLoader={setIsLoader}
        setOpenRemove={setOpenRemove}
        toggleNameModal={toggleNameModal}
        removeUrl={removeUrl}
        openRemove={openRemove}
        products={products}
        authWrapper={authWrapper}
        settings={settings}
      />
      <div className="container dashboard-container">
        <div
          style={{
            display:
              isLoader || settings?.loading || paymentSlice?.isPaymentApiLoading
                ? 'flex'
                : 'none',
          }}
          // className="loader"
        >
          {/* <img src={loaderSpinner} alt="Loader" /> */}
          <Loader />
        </div>
        {isNotRemoveSlide && (
          <div className="container remove_product_msg">
            <p>
              {removeMsg ? removeMsg : 'Cannot add more than 1 test product'}
            </p>
          </div>
        )}
        {showToast === 'false' && (
          <div className="header-snackbar">
            <PriorityHighRoundedIcon
              sx={{
                color: '#602C02',
                border: '2px solid #FF7200',
                borderRadius: '50%',
              }}
            />

            <div className="content">
              <b>Welcome to Esstart !</b>
              <div>
                {authWrapper?.userInfoData?.isEmailVerified === 'false' && (
                  <span>
                    <span className="snackbar-link" onClick={handleVerifyEmail}>
                      Click Here
                    </span>{' '}
                    to verify your email.&nbsp;
                  </span>
                )}
                {!authWrapper?.userInfoData?.name && (
                  <span>
                    You have not set your User Name yet.&nbsp;
                    <span
                      className="snackbar-link"
                      onClick={() => navigate('/credentials')}
                    >
                      Click Here
                    </span>{' '}
                    to Add User Name and Phone Number.&nbsp;
                  </span>
                )}
              </div>
            </div>
            <img
              src={CloseIcon}
              className="close_welcome_alert"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowToast('')}
              alt="Welcome Close"
            />
          </div>
        )}
        {!!authWrapper?.userInfoData?.hasMembership ? (
          <></>
        ) : (
          <div className="membership-banner flex-row align-items-center">
            <div className="flex-row align-items-center h-100 first-div">
              <div className="blob">
                <div className="content">
                  <p className="color-white">UPTO</p>
                  <p className="disc-num">70%</p>
                  <p className="color-white">OFF</p>
                </div>
              </div>
              <div className="flex-grow-1 mxy-1 para-12">
                <p className="para-1-txt">Join our Exclusive Esstart</p>
                <p className="para-2-txt">MEMBERSHIP PROGRAM</p>
              </div>
            </div>
            <div className="flex-row align-items-center second-div h-100">
              <div className="blob-2"></div>
              <div className="bg-white membership-txt d-flex flex-column justify-content-center align-items-start">
                <p className="para-4-txt">
                  Get up to 70% off on All Our Products for a Full Year!
                </p>
                <p>
                  ANNUAL MEMBERSHIP FEES $
                  {membershipPlan?.unit_amount / 100 || 0} ONLY
                </p>
              </div>
              <div className="blob-3"></div>
              <div className="mxy-1 btn-wrapper">
                <Buttons
                  text="Buy now"
                  className="buy-membership" //buy-membership class is for GTM
                  bgColor="#fff"
                  color="#000"
                  borderRadius={true}
                  boxShadow={true}
                  padding="0.75rem 3rem"
                  fontSize="1rem"
                  onClick={purchasePlan}
                />
              </div>
            </div>
          </div>
        )}

        <div className="container">
          <div className="flex-row container-header align-items-center">
            <h2>Welcome to Esstart!</h2>
            <div className="flex-row gap-4">
              {/* {!authWrapper?.userInfoData?.enableCommonQr &&
                authWrapper?.userInfoData?.hasMembership && (
                  <button
                    className="flex-row align-items-center gap-1 cursor-pointer sec-btn"
                    onClick={onEnableCommonQr}
                  >
                    <img src={BlueTick} className="blue-tick" alt="blue tick" />
                    Activate Master Qr
                  </button>
                )} */}

              {!!products?.productsList?.length && (
                <button
                  className="add-event-btn cursor-pointer"
                  onClick={() => navigate('/allproducts')}
                >
                  Add an activity
                </button>
              )}
            </div>
          </div>

          <div className="toggle-radio flex-row align-items-center gap-2 justify-content-end mt-2 mb-2">
            Hide Old Events
            <label className="switch">
              <input
                type="checkbox"
                defaultChecked={products.showUnUsedEvents}
                onChange={(e) =>
                  dispatch(setShowUnUsedEvents(e.target.checked))
                }
              />
              <span className="slider round"></span>
            </label>
          </div>

          <div className="row px-sm-0 flex-row">
            {products?.productsList && !!products?.productsList.length ? (
              products?.showUnUsedEvents ? (
                unUsedFilteredEvents.map((productData: any) => (
                  <DashboardCard
                    key={productData._id}
                    productData={productData}
                    productNamesWithChild={productNamesWithChild}
                    redirectionToPurchase={redirectionToPurchase}
                    handleManageRedirection={handleManageRedirection}
                    handleOpenRemoveModal={handleOpenRemoveModal}
                    toggleCreateNewSlideshowModal={
                      toggleCreateNewSlideshowModal
                    }
                  />
                ))
              ) : (
                products.productsList
                  .filter((prd: any) => prd?.distinctName !== 'member-mailer')
                  ?.map((productData: any) => (
                    <DashboardCard
                      key={productData._id}
                      productData={productData}
                      productNamesWithChild={productNamesWithChild}
                      redirectionToPurchase={redirectionToPurchase}
                      handleManageRedirection={handleManageRedirection}
                      handleOpenRemoveModal={handleOpenRemoveModal}
                      toggleCreateNewSlideshowModal={
                        toggleCreateNewSlideshowModal
                      }
                    />
                  ))
              )
            ) : (
              <></>
            )}
            {/* {authWrapper?.userInfoData?.enableCommonQr && (
              <div className="col-lg-4 col-md-6 col-sm-1 px-lg-2 px-md-1 my-2 card-wrapper">
                <Card>
                  <div className="card-content flex-grow-1">
                    <div className="dashboard-qr-comp">
                      <CommonQrWrapper />
                    </div>
                  </div>
                  <CardFooter>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Buttons
                        text="Manage"
                        bgColor="rgb(255, 114, 0)"
                        color="#fff"
                        borderRadius={true}
                        boxShadow={false}
                        padding="6px 10px"
                        onClick={() => navigate('/personalized-qr')}
                        fontSize="12px"
                        className="manage-btn"
                      />
                      <Buttons
                        text="Download QR"
                        bgColor="rgb(255, 114, 0)"
                        color="#fff"
                        borderRadius={true}
                        boxShadow={false}
                        padding="6px 10px"
                        onClick={onQrDownload}
                        fontSize="12px"
                        className="manage-btn"
                      />
                    </div>
                  </CardFooter>
                </Card>
              </div>
            )} */}

            {products?.productsList && !!products?.productsList.length ? (
              <></>
            ) : (
              <>
                <div
                  style={{
                    margin: '1 auto',
                    opacity: products?.loading ? 0 : 1,
                  }}
                >
                  <div className="no-event-msg text-center">
                    <h2>Test any product from the below options!</h2>
                  </div>
                  <div className="mt-5">
                    <GamesAndActivities />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const CommonQrWrapper = () => {
  const authWrapper: any = React.useContext(AuthContext)
  const products: any = useSelector((state: any) => state.products)
  const dispatch: any = useDispatch()

  const unUsedFilteredEvents = products?.productsList?.filter((event: any) => {
    const showDate = new Date(event?.showDate)
    const currentDate = new Date()
    const differenceInTime = currentDate.getTime() - showDate.getTime()
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24))

    return differenceInDays < 11 || event?.productStatus === 'test'
  })

  const changeConnectedQrCode = async (event: any) => {
    const res = await dispatch(
      editUserInfo({
        commonQrCodeId: authWrapper?.userInfoData?.commonQrCodeId,
        commonQrCodeAttachedWith: event.target.value,
      })
    )
    if (res?.payload?.data?.status === 'success') {
      authWrapper.setUserInfoData((prev: any) => ({
        ...prev,
        commonQrCodeAttachedWith: event.target.value,
      }))
      successToast('QR attached to new event successfully!')
    } else {
      errorToast('Something went wrong!')
    }
  }
  return (
    <>
      <p className="qr-heading txt-center">
        <b>Your Master QR Code</b>
      </p>
      <div className="qr-content">
        <div className="qr-img-container mt-1rem">
          <QRCode
            id="QRCode"
            size={500}
            style={{
              height: 'auto',
              maxWidth: '100%',
              width: '100%',
            }}
            value={`${process.env.REACT_APP_GUEST_WEBSITE_URL}?lookup=${authWrapper?.userInfoData?.commonQrCodeId}`}
            viewBox={`0 0 500 500`}
          />
        </div>
        <div className="mt-1rem">
          <p className="txt-center">
            Attach this QR with any of your event by selecting from dropdown
          </p>
          <div className="txt-center">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={authWrapper?.userInfoData?.commonQrCodeAttachedWith}
                onChange={changeConnectedQrCode}
                displayEmpty
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {unUsedFilteredEvents?.length > 0 &&
                  unUsedFilteredEvents?.map((prd: any) => (
                    <MenuItem key={prd?._id} value={prd?.activityCode}>
                      {prd?.showName}: {prd?.activityCode}
                    </MenuItem>
                  ))}
                {unUsedFilteredEvents?.length > 0 &&
                  unUsedFilteredEvents?.findIndex(
                    (prd: any) =>
                      prd?.activityCode ===
                      authWrapper?.userInfoData?.commonQrCodeAttachedWith
                  ) < 0 && (
                    <MenuItem
                      value={
                        authWrapper?.userInfoData?.commonQrCodeAttachedWith
                      }
                    >
                      Selected:{' '}
                      {authWrapper?.userInfoData?.commonQrCodeAttachedWith}
                    </MenuItem>
                  )}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  )
}
