import { Box } from '@mui/material'
import './no-devices-found.scss'

export const NoDevicesFound = () => {
  return (
    <>
      <Box component="div" className={`my-devices__no-device-box`} />
      <Box component="div" className={`my-devices__no-device-underline`} />
      <Box component="div" className={`my-devices__no-device-header-text`}>
        No Device Found
      </Box>
      <Box component="div" className={`my-devices__no-device-sub-text`}>
        No device added to My Device List, Please add a smart Device
      </Box>
    </>
  )
}
