import { createTheme, ThemeProvider } from '@mui/material'

const themeGlobal = createTheme({
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif !important',
  },
  palette: {
    primary: {
      main: '#FF7200',
    },
    secondary: {
      main: '#FF7200',
    },
    success: {
      main: '#009FB7',
    },
    info: {
      main: '#401C00',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontSize: '33px',
          lineHeight: '45px',
          fontWeight: 500,
        },
      },
    },
  },
})

interface IProps {
  children: JSX.Element
}

export const ThemeWrapper = ({ children }: IProps) => (
  <ThemeProvider theme={themeGlobal}>{children}</ThemeProvider>
)

const themeForMobNavList = createTheme({
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif !important',
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          postion: 'relative',
          '&:before': {
            content: "''",
            width: '4px',
            height: '100%',
            position: 'absolute',
            left: 0,
            backgroundColor: '#009FB7',
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
          },
          '&:hover': {
            backgroundColor: '#ffffff',
            '&:before': {
              opacity: 1,
            },
          },
          '&.active': {
            '&:before': {
              opacity: 1,
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Plus Jakarta Sans',
          color: '#202020',
          position: 'relative',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '18px',
          paddingTop: 2,
          paddingBottom: 2,
          color: '#202020 !important',
        },
      },
    },
  },
})

export const MobileNavList = ({ children }: IProps) => (
  <ThemeProvider theme={themeForMobNavList}>{children}</ThemeProvider>
)

const themeForWebNavList = createTheme({
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif !important',
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          postion: 'relative',
          '&:before': {
            content: "''",
            bottom: -4,
            right: 0,
            height: '5px',
            position: 'absolute',
            left: 0,
            backgroundColor: '#009FB7',
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
          },
          '&:hover': {
            backgroundColor: '#ffffff !important',
            '&:before': {
              opacity: 1,
            },
          },
          '&.active': {
            '&:before': {
              opacity: 1,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textSecondary: {
          color: '#202020',
          fontSize: 18,
          textTransform: 'inherit',
        },
      },
    },
  },
})

export const WebNavList = ({ children }: IProps) => (
  <ThemeProvider theme={themeForWebNavList}>{children}</ThemeProvider>
)

const buttonTheme = createTheme({
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif !important',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '6px 20px',
          lineHeight: '18px',
          borderRadius: '20px',
        },
        textPrimary: {
          backgroundColor: '#FF7200',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#FF7200',
            color: '#FFFFFF',
          },
        },
        textSecondary: {
          color: '#202020',
          '&:hover': {
            background: '#FFFFFF',
            color: '#202020',
          },
        },
      },
    },
  },
})

export const ButtonTheme = ({ children }: IProps) => (
  <ThemeProvider theme={buttonTheme}>{children}</ThemeProvider>
)

const accountTheme = createTheme({
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif !important',
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#202020 !important',
        },
        h5: {
          fontSize: '18px',
          lineHeight: '22px',
          fontWeight: 600,
        },
        body1: {
          fontSize: '16px',
          fontWeight: 500,
        },
        body2: {
          fontSize: '16px',
          fontWeight: 500,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '6px 20px',
          lineHeight: '18px',
        },
        textPrimary: {
          borderRadius: '50px',
          backgroundColor: '#FF7200',
          color: '#FFFFFF',
          fontSize: '14px',
          fontWeight: 300,
          '&:hover': {
            backgroundColor: '#FF7200',
            color: '#FFFFFF',
          },
        },
        textSecondary: {
          borderRadius: '50px',
          color: '#202020',
          fontSize: '16px',
          fontWeight: 500,
          '&:hover': {
            backgroundColor: '#FFFFFF',
          },
        },
      },
    },
  },
})

export const AccountDropdownTheme = ({ children }: IProps) => (
  <ThemeProvider theme={accountTheme}>{children}</ThemeProvider>
)

/************************ */

const tableTheme = createTheme({
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif !important',
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          backgroundColor: '#009FB7',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#202020',
          paddingLeft: 0,
          fontSize: '16px',
          lineHeight: '18px',
          '&:first-of-type': {
            paddingLeft: 10,
          },
        },
        head: {
          color: '#ffffff',
          fontWeight: 300,
          paddingTop: 14,
          paddingBottom: 13,
        },
        body: {
          paddingTop: 14,
          paddingBottom: 13,
        },
      },
    },
  },
})

export const TableTheme = ({ children }: IProps) => (
  <ThemeProvider theme={tableTheme}>{children}</ThemeProvider>
)

/************************ */

const formTheme = createTheme({
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif !important',
  },
  components: {
    MuiFormGroup: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#202020',
          paddingLeft: 0,
          fontSize: '16px',
          lineHeight: '18px',
        },
      },
    },
  },
})

export const FormTheme = ({ children }: IProps) => (
  <ThemeProvider theme={formTheme}>{children}</ThemeProvider>
)
