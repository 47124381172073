import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { decodeToken } from 'react-jwt'
import { socialLoginSuccess } from '../../redux/auth'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import welcome_icon from '../../assets/images/welcome_icon.svg'
import weblogo from '../../assets/logo/web_logo.svg'
import styled from 'styled-components'

const WelcomeWrapper = styled.div`
  .button-container {
    display: flex;
    justify-content: center;
    margin: 0px 0 24px 0;

    &__start {
      width: 198px;
      height: 40px;
      background: #ff7200 0% 0% no-repeat padding-box;
      border-radius: 20px;
      color: #ffffff;
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 113px;
  }

  .welcome-Img {
    display: inline-block;
    align-content: stretch;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 333px;
    padding-left: 100px;
  }

  .text {
    font: normal normal normal 34px/42px Plus Jakarta Sans;
  }

  .logo {
    height: 43px;
    padding-right: 200px;
  }
`

export const SocialLoginSuccess = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const [welcomeModal, toggleWelcomeModal] = useState<boolean>(false)
  const auth: any = useSelector((state: any) => state.auth)

  useEffect(() => {
    if (
      location.hash ===
      '#error_description=PreSignUp+failed+with+error+Email+is+already+registered.+&error=invalid_request'
    ) {
      window.location.href = `${window.location.origin}/login?error=EmailAlreadyRegistered`
    } else if (location.hash || ''.match(/access_token=([^&]*)/)?.length) {
      const accessTokenArr = location.hash.match(/access_token=([^&]*)/)
      const accessToken = accessTokenArr?.length ? accessTokenArr[1] : ''
      const decodeJWT: any = decodeToken(accessToken)
      dispatch(
        socialLoginSuccess({ token: accessToken, expiry: decodeJWT?.exp })
      )
    }
    // toggleWelcomeModal(true);
  }, [])

  useEffect(() => {
    if (auth?.accessToken) {
      handlePreserveRouting()
    }
  }, [auth])

  const handlePreserveRouting = () => {
    const next = localStorage.getItem('next')
    const queryString = localStorage.getItem('queryString')
    let url = `${queryString ? `/dashboard?${queryString}` : '/dashboard'}`
    if (next) {
      url = next
      localStorage.removeItem('next')
    }
    if (queryString) {
      localStorage.removeItem('queryString')
    }
    window.location.href = '/dashboard'
    navigate(url)
  }

  const handleRedirect = () => {
    window.location.href = `${window.location.origin}`
    toggleWelcomeModal(false)
  }

  return (
    <div>
      <Dialog open={welcomeModal}>
        <WelcomeWrapper>
          <DialogContent className="dialogStyle">
            <div className="row">
              <div className="col">
                <img src={weblogo} alt="esstart logo" className="logo" />
              </div>
              <div className="col">
                <span className="text">Welcome To Esstart !</span>
              </div>
            </div>
            <div className="welcome-Img">
              <img src={welcome_icon} alt="welcome icon" />
            </div>

            <div className="button-container">
              <Button
                className="button-container__start"
                onClick={handleRedirect}
              >
                {' '}
                LETS HAVE FUN{' '}
              </Button>
            </div>
          </DialogContent>
        </WelcomeWrapper>
      </Dialog>
    </div>
  )
}
