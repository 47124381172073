import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import {
  Button,
  TextField,
  IconButton,
  Container,
  Box,
  Alert,
  Typography,
  AlertTitle,
  CircularProgress,
} from '@mui/material'
import { SignInWrapper } from './style'
import { getProduct } from '../../redux/products'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Logo from '../../assets/logo/web_logo.svg'
import VisibleOn from '../../assets/images/show-eye.svg'
import VisibleOff from '../../assets/images/hide-eye.svg'
import { signInUser, resendEmail } from '../../redux/auth'
import SocialLogin from '../socialLogin/socialLogin'
import { Modal, getCookie } from 'esstart-components'
import { ForgotPassword } from '../forgotPassword/ForgotPassword'
import Cookies from 'js-cookie'
import toast from 'react-hot-toast'
import ReCAPTCHA from 'react-google-recaptcha'

export const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch<any>()
  const productId = searchParams.get('productId')
  const planId = searchParams.get('planId')
  const from = searchParams.get('from')
  const searchParamError = searchParams.get('error')
  const [resentEmailMsg, setResentEmailMsg] = useState(false)
  const [openForgetPwdModal, setOpenForgetPwdModal] = useState<string>('')
  const [showPassword, setShowPassword] = useState(false)
  const [popupToken, setPopupToken] = useState('')

  planId &&
    Cookies.set('planId', planId, {
      expires: 1,
    })
  productId &&
    Cookies.set('productId', productId, {
      expires: 1,
    })
  from &&
    Cookies.set('from', from, {
      expires: 1,
    })

  //this is for popup that accepts token
  const loginPopup = searchParams.get('rbsh')

  const navigate = useNavigate()

  const product: any = useSelector(
    (state: any) => state.products?.selectedProduct
  )
  const resetEmail: any = useSelector(
    (state: any) => state.auth?.resetEmailStatus
  )

  const onLoginSuccess = () => {
    const next = localStorage.getItem('next')
    let url = `${
      productId ? `/dashboard?productId=${productId}` : '/dashboard'
    }`
    const queryString = localStorage.getItem('queryString')
    if (next) {
      url = `${next}`
      localStorage.removeItem('next')
    }
    if (queryString) {
      localStorage.removeItem('queryString')
    }
    window.location.href = `${url}`
    navigate(url)
  }

  useEffect(() => {
    if (searchParamError === 'EmailAlreadyRegistered') {
      setTimeout(() => {
        toast('Email is already registered !', {
          style: {
            backgroundColor: '#fcd2d6',
            color: '#861d26',
          },
        })
        setSearchParams('')
      }, 0)
    }
  }, [])

  useEffect(() => {
    if (productId) {
      dispatch(getProduct({ productId: productId }))
    }
  }, [searchParams, dispatch])

  const onHomeClick = () => {
    const url: any = process.env.REACT_APP_WEBSITE_URL_LIVE
    window.location.href = url
  }

  useEffect(() => {
    if (resetEmail) {
      setResentEmailMsg(true)
    } else {
      setResentEmailMsg(false)
    }
  }, [resetEmail])

  useEffect(() => {
    if (resentEmailMsg) {
      setTimeout(() => {
        setResentEmailMsg(false)
      }, 6000)
    }
  }, [resentEmailMsg])

  const [loading, toggleLoading] = useState<boolean>(false)
  const [alert, setAlert] = useState(false)
  const [alertContent, setAlertContent] = useState('')

  let captcha: any
  const setCaptchaRef = (ref: any) => {
    if (ref) {
      return (captcha = ref)
    }
  }
  interface IErrorSingIn {
    email?: string
    password?: string
    rememberCheck?: boolean
    reCaptcha?: string
  }

  const signInValidate: any = (values: any) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i

    const errors = {} as IErrorSingIn

    if (!values.email) {
      errors.email = 'Please enter the email'
    } else if (!emailPattern.test(values.email)) {
      errors.email = 'Valid Email Required'
    }

    if (!values.password) {
      errors.password = 'Please enter the password'
    }

    if (!values.reCaptcha) {
      errors.reCaptcha = 'Please enter the captcha'
    }

    return errors
  }

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      reCaptcha: '',
      // rememberCheck: true,
    },
    validate: signInValidate,
    onSubmit: async (values: any) => {
      const { rememberCheck, email, password, reCaptcha } = values
      // if (rememberCheck) {
      toggleLoading(true)
      const res = await dispatch(
        signInUser({ email: email?.toLowerCase()?.trim(), password, reCaptcha })
      )

      if (res.type === 'auth/signin/fulfilled') {
        onLoginSuccess()
      }
      if (res.type === 'auth/signin/rejected') {
        setAlert(true)
        setAlertContent(res.payload.message)
        captcha.reset()
      }

      toggleLoading(false)
      // }
    },
  })

  const onRedirectToSignUp = () => {
    if (!!productId && !!planId && !!from)
      navigate(`/signup?productId=${productId}&planId=${planId}&from=${from}`)
    else if (!!productId) navigate(`/signup?productId=${productId}`)
    else navigate('/signup')
  }

  const handleResendEmail = (e: any) => {
    e.preventDefault()
    const userName = getCookie('username')
    if (userName) {
      setAlert(false)
      dispatch(resendEmail({ userName }))
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleRecaptchaVerify = (token: any) => {
    setFieldValue('reCaptcha', token)
  }

  const handleTokenPopupBtnClick = () => {
    Cookies.set('accessToken', popupToken)
    setSearchParams('')
    setPopupToken('')
    window.location.reload()
  }

  const closeTokenPopup = () => {
    setSearchParams('')
    setPopupToken('')
  }

  return (
    <SignInWrapper>
      {loginPopup === 'rbsh' && (
        <Modal open={true} size="xl">
          <div className="token-popup d-flex flex-column gap-4 my-4 align-items-center">
            <textarea
              rows={5}
              value={popupToken}
              onChange={(e) => setPopupToken(e.target.value)}
            />
            <div className="d-flex gap-4">
              <button className="cursor-pointer" onClick={closeTokenPopup}>
                Close
              </button>
              <button
                className="cursor-pointer"
                onClick={handleTokenPopupBtnClick}
              >
                Let's go
              </button>
            </div>
          </div>
        </Modal>
      )}
      <Box className="login_background">
        <Container>
          <div className="form__wrapper login_page">
            <div className="content_part">
              <div className="login_menu" onClick={onHomeClick}>
                <IconButton>
                  <ArrowBackIosIcon />
                </IconButton>
                <p>Home</p>
              </div>
              <img src={Logo} alt="Logo" className="website_logo" />
              {!!product ? (
                <div className="product_box hide-for-small-screen">
                  <img src={product?.cardImgURL} alt="Product" />
                  <h3>{product?.displayTitle}</h3>
                  <p>{product?.shortDesc1} </p>
                  <p>{product?.shortDesc2}</p>
                </div>
              ) : (
                <h5 className="hide-for-small-screen">
                  The Most
                  <p>
                    <span>Unforgettable </span>
                    <span>Events</span>
                  </p>
                  Start with Esstart
                </h5>
              )}
            </div>
            {openForgetPwdModal ? (
              <ForgotPassword
                openForgetPwdModal={openForgetPwdModal}
                setOpenForgetPwdModal={setOpenForgetPwdModal}
              />
            ) : (
              <></>
            )}
            <div className="login_form">
              <Box className="page_banner d-flex flex-column align-items-center">
                <h2 className="title">Welcome Back !</h2>
                <p className="subtitle">
                  Please login to continue with Esstart
                </p>
                <div className="start">
                  Don't have an account ?
                  <Button className="start__login" onClick={onRedirectToSignUp}>
                    SIGNUP HERE
                  </Button>
                </div>
                <div className="flex-row tab-view">
                  <div className="text-center">Login</div>
                  <div
                    className="cursor-pointer text-center"
                    onClick={onRedirectToSignUp}
                  >
                    Signup
                  </div>
                </div>
                <div className="full-strike"></div>
              </Box>
              <form onSubmit={handleSubmit}>
                <div className="social-wrapper">
                  <SocialLogin />
                </div>

                <div className="strike">
                  <span> or </span>
                </div>

                {resentEmailMsg && (
                  <>
                    <Alert severity="success" style={{ marginBottom: '15px' }}>
                      <AlertTitle>Successfully sent email</AlertTitle>
                    </Alert>
                  </>
                )}
                {alert && alertContent === 'User is not confirmed.' && (
                  <Alert severity="error" style={{ marginBottom: '15px' }}>
                    <AlertTitle>
                      Oops: Your email account was not verified.
                    </AlertTitle>
                    We sent you a link to your email account, please check your
                    email & spam folder, click on the link to verify your
                    account. Did not receive the email?{' '}
                    <a href="/#" onClick={(e) => handleResendEmail(e)}>
                      Click here
                    </a>
                  </Alert>
                )}

                {alert && alertContent !== 'User is not confirmed.' && (
                  <Alert style={{ marginBottom: '15px' }} severity="error">
                    {alertContent}
                  </Alert>
                )}

                <div className="input-field pt-0">
                  <span>
                    Enter Email Address<span className="color-red">*</span>
                  </span>
                  <TextField
                    fullWidth
                    // label="Email ID"
                    id="email"
                    className="textField"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.email && !!touched.email}
                  />
                </div>

                {errors.email && touched.email ? (
                  <div className="errorMsg">{errors.email as string}</div>
                ) : null}

                <div className="input-field">
                  <span>
                    Enter Password<span className="color-red">*</span>
                  </span>
                  <TextField
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    // label="Enter Password"
                    id="password"
                    className="textField"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.password && !!touched.password}
                    InputProps={{
                      endAdornment: (
                        <div
                          className="cursor-pointer"
                          onClick={handleClickShowPassword}
                        >
                          <img
                            src={showPassword ? VisibleOff : VisibleOn}
                            alt="toggle show password"
                          />
                        </div>
                      ),
                    }}
                  />
                </div>
                {errors.password && touched.password ? (
                  <div className="errorMsg">{errors.password as string}</div>
                ) : null}

                <ReCAPTCHA
                  className="mt-3"
                  ref={(r) => setCaptchaRef(r)}
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY!}
                  onChange={handleRecaptchaVerify}
                />
                {errors.reCaptcha && touched.reCaptcha ? (
                  <div className="errorMsg">{errors.reCaptcha as string}</div>
                ) : null}
                <p
                  className="forgotPwd float-end"
                  onClick={() => setOpenForgetPwdModal('enterEmailModal')}
                >
                  Forgot Password?
                </p>
                <div className="button-container">
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      className="button-container__start remove_btn_hover"
                      type="submit"
                    >
                      Login
                    </Button>
                  )}
                </div>

                <Typography
                  marginTop="15px"
                  fontSize="14px"
                  className="privacy_policychange"
                >
                  By clicking button above, you agree to our{' '}
                  <a
                    className="anchor"
                    href="https://www.esstart.com/tos"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of use
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.esstart.com/p-policy"
                    className="anchor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policies
                  </a>
                </Typography>
              </form>
            </div>
          </div>
        </Container>
      </Box>
    </SignInWrapper>
    // </PublicRoute>
  )
}
