import React from 'react'
import EsstartRoutes from './routes'
import { Toaster } from 'react-hot-toast'
import './assets/app.scss'

const App = () => {
  return (
    <div className="app app__wrapper">
      <EsstartRoutes />
      <Toaster />
    </div>
  )
}

export default App
