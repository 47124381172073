import styled from 'styled-components'

export const CredentialsWrapper = styled.div`
  margin: 25px 50px;
  @media screen and (min-width: 320px) and (max-width: 480px) {
    margin: 25px 20px;
  }

  .forgot-pwd-btn {
    border: 0;
    outline: 0;
    color: #ff7200;
    background-color: transparent;
    text-decoration: underline;
    font-size: 1rem;
  }
`

export const PersonalInfoWrapper = styled.div`
  .pencil-icon {
    fill: #fff;
  }
  .app-pri-color {
    color: #ff7200;
  }
  .profile-image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
  }
  .phone-no__input {
    flex: 3;
    width: 100%;
    padding-right: 10px;
    input {
      font-size: 1rem;
      padding: 16.5px 14px;
    }
  }
  .otp-inp {
    display: inline-block;
    margin: 0px auto;
    border: none;
    padding: 0px;
    width: 9ch;
    background: repeating-linear-gradient(
        90deg,
        black 0px,
        black 1ch,
        transparent 0px,
        transparent 1.5ch
      )
      0px 100% / 10ch 2px no-repeat;
    letter-spacing: 0.5ch;
    font: 2rem 'Ubuntu Mono', monospace !important;
  }
  .otp-inp:focus {
    outline: none;
    color: black;
  }
  .otp-inp::-webkit-outer-spin-button,
  .otp-inp::-webkit-inner-spin-button {
    display: none;
  }
`
