import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box, Breadcrumbs, Link } from '@mui/material';
import CredentialsTab from './CredentialsTab';
import PersonalInfoTab from './personalInfo';
import { CredentialsWrapper } from './style';
import { ThemeWrapper } from '../themes';

const MyCredentials = () => {

  const [value, setValue] = useState<number>(0);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      href="/"
      color="#009FB7"
      onClick={() => { }}
    >
      Account
    </Link>,
    <Typography key="2">
      My Profile
    </Typography>,
  ];

  return (
    <ThemeWrapper>
      <CredentialsWrapper>
        <Box component="div" className={`my-credentials__title-box`}>
          <Typography variant="h5" >Manage Profile</Typography>
          <Breadcrumbs separator=">" sx={{ marginTop: '10px' }}>
            {breadcrumbs}
          </Breadcrumbs>
          <Typography sx={{ color: '#707070', marginTop: '4px' }}>You can manage your name and mobile number here.</Typography>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '35px' }}>
          <Tabs value={value} onChange={onTabChange} textColor="primary" indicatorColor="primary">
            <Tab label="Personal Info" />
            <Tab label="Credentials" />
          </Tabs>
        </Box>
        <div
          role="tabpanel"
          hidden={value !== 0}
          id={`simple-tabpanel-${0}`}
          aria-labelledby={`simple-tab-${0}`}
        >
          <PersonalInfoTab />
        </div>
        <div
          role="tabpanel"
          hidden={value !== 1}
          id={`simple-tabpanel-${1}`}
          aria-labelledby={`simple-tab-${1}`}
        >
          <CredentialsTab />
        </div>
      </CredentialsWrapper>
    </ThemeWrapper>
  )
}

export default MyCredentials;