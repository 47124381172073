import styled from 'styled-components'

export const DialogWrapper = styled.div`
  .first-para {
    padding: 15px 0 30px 0;
    font: normal normal normal 16px/19px Plus Jakarta Sans;
  }

  .span {
    display: flex;
    padding-bottom: 15px;
    font: normal normal normal 14px/18px Plus Jakarta Sans;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin: 30px 0 24px 0;

    &__start {
      width: 198px;
      height: 40px;
      background: #ff7200 0% 0% no-repeat padding-box;
      border-radius: 20px;
      color: #ffffff;
    }
  }

  .errorMsg {
    padding-top: 10px;
    color: #d32f2f;
    font-size: 14px;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font: normal normal normal 25px/39px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
  }
  .remove_btn_hover {
    background: 0% 0% no-repeat padding-box padding-box rgb(255, 114, 0) !important;
  }
`

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: black;
  .loader {
    position: absolute;
    margin: auto;
    border: 5px solid #eaf0f6;
    border-radius: 50%;
    border-top: 5px solid #ff7200;
    width: 50px;
    height: 50px;
    animation: spinner 2s linear infinite;
  }

  .loaderMain {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: #00000050;
    z-index: 1111;
    align-items: center;
    justify-content: center;
  }

  .text {
    position: absolute;
    margin: auto;
    text-align: center;
    font-family: Gotham, 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
    font-size: 18px;
    margin-top: 120px;
    color: #fff;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`
