import React, { useEffect, useState } from 'react'
import { Alert, Box, Container, Grid, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
// import { useSelector, useDispatch, useSearchParams } from 'esstart-common-component';
import './my-devices.scss'
import { NoDevicesFound } from './no-devices-found/NoDevicesFound'
import { AddDevice } from './add-device/AddDevice'
import { DeviceList } from './device-list/DeviceList'
import { getMyDevices, clearMessage } from '../../redux/mydevices'
import loaderSpinner from '../../assets/images/esstart_spinner.gif'
import { Loader } from 'esstart-components'

export const MyDevices = () => {
  const dispatch = useDispatch<any>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [paramTvCode, setParamTvCode] = useState(
    searchParams.get('tvCode') || ''
  )

  const devices: any = useSelector((state: any) => state.myDevices.devicesList)
  const loading: any = useSelector((state: any) => state.myDevices.loading)
  const message: any = useSelector((state: any) => state.myDevices.message)
  const responseStatus: any = useSelector(
    (state: any) => state.myDevices.responseStatus
  )
  const [open, setOpen] = React.useState(false)
  const [alert, setAlert] = React.useState(false)
  const [alertContent, setAlertContent] = useState('')
  const [toggleNameModal, setToggleNameModal] = React.useState(false)
  const [deviceId, setDeviceId] = React.useState('')

  //const [available, setAvailable] = React.useState(devices && devices.length > 0);
  const openAddDevice = () => {
    setOpen(true)
  }
  const closeAddDevice = () => {
    setOpen(false)
    setParamTvCode('')
    setToggleNameModal(false)
  }
  const closeAndAddName = () => {
    setParamTvCode('')
    setOpen(true)
    setToggleNameModal(true)
  }

  useEffect(() => {
    dispatch(getMyDevices())
  }, [message])

  useEffect(() => {
    if (responseStatus === 'success' && message) {
      if (searchParams.has('tvCode')) {
        searchParams.delete('tvCode')
        setSearchParams(searchParams)
      }
      setAlert(responseStatus)
      setAlertContent(message)
    } else {
      setAlert(false)
      setAlertContent('')
    }
  }, [responseStatus, message, alert, alertContent])

  useEffect(() => {
    if (alert && alertContent) {
      setTimeout(() => {
        dispatch(clearMessage())
      }, 4000)
    }
  }, [alert, alertContent, dispatch])

  useEffect(() => {
    if (paramTvCode && !open) {
      openAddDevice()
    }
  }, [paramTvCode, open])

  return (
    <Container className={`my-devices my-devices__layout`} maxWidth="lg">
      <Box component="div" className={`my-devices__title-box`}>
        <Grid container direction="column" className={`my-devices__text`}>
          <Grid item className={`my-devices__text header`}>
            My Devices
          </Grid>
          <Grid item className={`my-devices__text normal`}>
            You can Manage your devices like add or remove from here. You Can
            Add maximum of 5 Smart Devices
            {alert && (
              <Alert
                severity={`${
                  responseStatus === 'success' ? 'success' : 'error'
                }`}
              >
                {alertContent}
              </Alert>
            )}
          </Grid>
        </Grid>
      </Box>

      {!loading && (
        <>
          <Box component="div" className={`my-devices__deviceslist-box`}>
            {devices?.length > 0 ? (
              <DeviceList devices={devices}></DeviceList>
            ) : (
              <></>
            )}
          </Box>
          {devices?.length === 0 ? <NoDevicesFound /> : <></>}
          <div className={`btn add-devices`} onClick={openAddDevice}></div>
          <AddDevice
            open={open}
            onClose={closeAddDevice}
            otpCode={paramTvCode}
            closeAndAddName={closeAndAddName}
            toggleNameModal={toggleNameModal}
            deviceId={deviceId}
            setDeviceId={setDeviceId}
          />
        </>
      )}
      <div
        style={{ display: loading ? 'flex' : 'none' }}
        // className="loader"
      >
        {/* <img src={loaderSpinner} alt="Loader" /> */}
        <Loader />
      </div>
    </Container>
  )
}
