import React from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardFooter,
  Buttons,
} from 'esstart-components'
import moment from 'moment'

export const DashboardCard = (props: any) => {
  const {
    productData,
    productNamesWithChild,
    redirectionToPurchase,
    handleManageRedirection,
    handleOpenRemoveModal,
    toggleCreateNewSlideshowModal,
  } = props

  const isNotMemberMailerPrd = productData?.distinctName !== 'member-mailer'

  return (
    <div className="col-lg-4 col-md-6 col-sm-1 px-lg-2 px-md-1 my-2 card-wrapper">
      <Card>
        <CardMedia
          image={productData?.prodcutMetadata?.cardImgURL}
          alt={productData?.prodcutMetadata?.displayTitle}
        />
        <CardContent className="flex-grow-1">
          {/* <PriceLable cardLable={true} price={10} /> */}
          {isNotMemberMailerPrd && (
            <p
              className={`product-status flex-row align-items-center ${
                productData?.productStatus === 'test' ||
                !productData?.productStatus
                  ? 'test-status'
                  : 'paid-status'
              }`}
            >
              <span
                className="circle"
                style={{
                  backgroundColor:
                    productData?.productStatus === 'test' ||
                    !productData?.productStatus
                      ? '#F59403'
                      : '#16B096',
                }}
              ></span>
              <span>
                {productData?.productStatus === 'test' ||
                !productData?.productStatus
                  ? 'Test'
                  : 'Paid'}
              </span>
            </p>
          )}

          <h5>{productData?.prodcutMetadata?.displayTitle}</h5>

          {isNotMemberMailerPrd && (
            <>
              <div className="mt-2">
                <p className="fs-13">Event</p>
                <b className="fs-13">{productData.showName}</b>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                  <p className="fs-13">For Date</p>
                  <b className="fs-13">
                    {moment(productData.showDate).format('MM/DD/yyyy')}
                  </b>
                </div>
                <div>
                  <p className="fs-13">Activity Code</p>
                  <b className="fs-13">{productData?.uniqueCode}</b>
                </div>
                <div>
                  <p className="fs-13">
                    No. of{' '}
                    {
                      productNamesWithChild[
                        `${productData?.prodcutMetadata?.distinctName}`
                      ]?.child
                    }
                  </p>
                  <b className="fs-13">
                    {productData?.prodcutMetadata?.totalEntries}
                  </b>
                </div>
              </div>
            </>
          )}
        </CardContent>
        <CardFooter>
          <div className="d-flex justify-content-between align-items-center mb-3">
            {/* @ts-ignore */}
            <div>
              {(productData?.productStatus === 'test' ||
                !productData?.productStatus) &&
              productData?.role !== 'moderator' &&
              isNotMemberMailerPrd ? (
                <Buttons
                  text="Purchase"
                  bgColor="#fff"
                  color="rgb(255, 114, 0)"
                  borderRadius={true}
                  boxShadow={true}
                  padding="6px 10px"
                  onClick={() => redirectionToPurchase(productData)}
                  fontSize="12px"
                />
              ) : (
                <></>
              )}
              <Buttons
                text="Manage"
                bgColor="rgb(255, 114, 0)"
                color="#fff"
                borderRadius={true}
                boxShadow={false}
                padding="6px 10px"
                onClick={() => handleManageRedirection(productData)}
                fontSize="12px"
                className="manage-btn"
              />
            </div>
            {productData?.relation ||
            productData?.role === 'moderator' ||
            productData?.productStatus === 'subscription' ? (
              <></>
            ) : (
              <Buttons
                text="Remove"
                bgColor="unset"
                color="rgb(25, 118, 210)"
                borderRadius={false}
                boxShadow={false}
                padding="0"
                fontSize="12px"
                onClick={(e: any) => handleOpenRemoveModal(productData)}
              />
            )}
            {productData?.productStatus === 'subscription' && (
              <Buttons
                text="Create New Spotlight"
                bgColor="#fff"
                color="rgb(255, 114, 0)"
                borderRadius={true}
                boxShadow={true}
                padding="6px 10px"
                onClick={() => {
                  toggleCreateNewSlideshowModal(productData?._id)
                }}
                fontSize="12px"
              />
            )}
          </div>
        </CardFooter>
      </Card>
    </div>
  )
}
